
import { defineComponent } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'PracovnikView',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [
            { nazev: 'Nástěnka', page: { name: 'nastenka.page' } },
            { nazev: 'Pracovníci', page: { name: 'pracovnik-list.page' } },
        ])
    },
})
