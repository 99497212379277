
import { defineComponent } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'PoziceView',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [{ nazev: 'Administrace' }, { nazev: 'Pozice', page: { name: 'pozice-list.page' } }])
    },
})
