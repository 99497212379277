
import { useCheckChanges, useSnackbar } from '@/core/composable'

import { Slovnik } from '@/models/slovnik'
import { VForm } from '@/models/vuetify/vform'
import { ODataService } from '@/services/odata.service'
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'SlovnikDetailPage',
    props: {
        slovnikId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const odata = new ODataService()
        const snackbar = useSnackbar()
        const store = useStore()
        const router = useRouter()

        const { changed } = useCheckChanges()

        const item = ref<Slovnik>({} as Slovnik)
        const saving = ref(false)
        const form = ref({} as VForm)

        const { slovnikId } = props

        const rules = {
            zkratkaRules: [(v: string) => !!v || 'Zkratka musí být vyplněna', (v: string) => (v && v.length <= 10) || 'Zkratka musí mít maximálně 10 znaků'],
            nazevRules: [(v: string) => !!v || 'Název musí být vyplněn'],
        }

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const fetchItem = () => {
            odata
                .getById<Slovnik>('slovnik', props.slovnikId, {
                    expand: { upravilUzivatel: { select: ['celeJmeno'] }, vytvorilUzivatel: { select: ['celeJmeno'] } },
                })
                .then((a) => (item.value = a.data))
                .finally(() => (changed.value = false))
        }

        const create = () => {
            return odata.post('slovnik', item.value)
        }

        const update = () => {
            return odata.put('slovnik', slovnikId, item.value)
        }

        const save = () => {
            if (!form.value.validate()) return
            saving.value = true

            const promise = slovnikId ? update() : create()
            promise
                .then(() => {
                    snackbar.success('Slovník byl uložen')
                    changed.value = false

                    router.push({ name: 'slovnik-list.page' })
                })
                .catch(() => snackbar.error('Slovník se nepodařilo uložit'))
                .finally(() => (saving.value = false))
        }

        watch(
            () => props.slovnikId,
            (newValue) => {
                if (newValue) {
                    fetchItem()
                }
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        return {
            item,
            saving,
            form,
            rules,
            changed,
            isReadOnly,

            save,
        }
    },
})
