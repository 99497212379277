
import { useSnackbar } from '@/core/composable'
import { round } from '@/helpers/round'
import { Finance, odpracovanoHodTmp } from '@/models/finance'
import { PenezniPolozka, PenezniPolozkaInput } from '@/models/penezni-polozka'
import { VForm } from '@/models/vuetify/vform'
import { ODataService } from '@/services/odata.service'
import { orderBy } from 'lodash'
import { nanoid } from 'nanoid'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
    props: {
        value: Object,
        readonly: Boolean,
        datumReadonly: Boolean,
    },
    emits: ['close'],
    setup(props, { emit }) {
        const odata = new ODataService()
        const snackbar = useSnackbar()

        const changed = ref(false)
        const saving = ref(false)
        const deleting = ref(false)

        const form = ref({} as VForm)
        const formPenezniPolozka = ref({} as VForm)

        const item = ref<Finance>({} as Finance)

        const titlePenezniPolozka = ref('')
        const isOdmenaMesic = ref(false)
        const isPokuta = ref(false)
        const isSrazka = ref(false)
        const penezniPolozkas = ref<PenezniPolozkaInput[]>([])

        const penezniPolozkaDialog = ref(false)
        const changedPenezniPolozka = ref(false)

        watch(
            () => props.value,
            () => {
                item.value = { ...(props.value as any) }

                nextTick(() => {
                    form.value.resetValidation()
                    changed.value = false
                })
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        watch(
            () => penezniPolozkaDialog,
            () => {
                if (!penezniPolozkaDialog.value) {
                    formPenezniPolozka.value.resetValidation()
                    changedPenezniPolozka.value = false
                } else {
                    penezniPolozkas.value = []
                }
            }
        )

        watch(
            () => penezniPolozkas,
            () => {
                changedPenezniPolozka.value = true
            },
            { deep: true }
        )

        const celkemZaklad = computed(() => {
            if (!isNaN(+item.value.odpracovanoHod) && !isNaN(+item.value.sazbaHod)) {
                return round(
                    odpracovanoHodTmp(+item.value.odpracovanoHod, +item.value.srazka, +item.value.pokuta, +item.value.sazbaHod) * +item.value.sazbaHod,
                    2
                )
            }

            return null
        })

        const celkemOdmena = computed(() => {
            if ((!isNaN(+item.value.odpracovanoHod) || !isNaN(+item.value.odpracovanoHppHod)) && !isNaN(+item.value.sazbaOdmenaHod)) {
                var result = round(
                    (odpracovanoHodTmp(+item.value.odpracovanoHod, +item.value.srazka, +item.value.pokuta, +item.value.sazbaHod) +
                        +(item.value.odpracovanoHppHod ?? 0)) *
                        +item.value.sazbaOdmenaHod +
                        +(item.value.odmenaMes ?? 0) +
                        +(item.value.odmenaPrac ?? 0),
                    2
                )
                return result
            }

            return null
        })

        const celkemAgentura = computed(() => {
            if (!isNaN(+item.value.odpracovanoHod) && !isNaN(+item.value.sazbaAgenturaHod)) {
                return round(
                    odpracovanoHodTmp(+item.value.odpracovanoHod, +item.value.srazka, +item.value.pokuta, +item.value.sazbaHod) * +item.value.sazbaAgenturaHod,
                    2
                )
            }

            return null
        })

        const celkemVyplata = computed(() => {
            if (celkemZaklad.value !== null && celkemOdmena.value !== null) {
                return round(celkemZaklad.value + celkemOdmena.value, 2)
            }

            return null
        })

        const celkemFaktura = computed(() => {
            if (celkemVyplata.value !== null && celkemAgentura.value !== null) {
                return round(celkemVyplata.value + celkemAgentura.value, 2)
            }

            return null
        })

        const rules = {
            datumRequired: [(v: string) => !!v || 'Datum musí být vyplněn'],
            typPenezniPolozkaNazev: [(v: string) => !!v || 'Typ musí být vyplněn'],
        }

        const create = () => {
            return odata.post<Finance>('finance', item.value)
        }

        const update = () => {
            return odata.put<Finance>('finance', item.value.id!, item.value)
        }

        const save = async () => {
            if (!form.value.validate()) {
                return
            }

            var financeExists =
                (
                    await odata.getList<Finance>('finance', {
                        count: true,
                        filter: {
                            pracovnikId: { eq: item.value.pracovnikId },
                            'year(datum)': { eq: +item.value.datum!.toString().split('-')[0] },
                            'month(datum)': { eq: +item.value.datum!.toString().split('-')[1] },
                        },
                    })
                ).data['@odata.count'] > 0

            if (!item.value.id && financeExists) {
                snackbar.error('Záznam s tímto datumem již existuje.')
                return
            }

            var isUzamceno =
                (
                    await odata.getList('uzamceno', {
                        filter: {
                            rok: { eq: +item.value.datum!.toString().split('-')[0] },
                            mesic: { eq: +item.value.datum!.toString().split('-')[1] },
                        },
                        count: true,
                    })
                ).data['@odata.count'] > 0

            if (isUzamceno) {
                snackbar.error('Tento měsíc je již uzavřen')
                return
            }

            saving.value = true

            const promise = item.value.id ? update() : create()
            promise
                .then(() => {
                    snackbar.success('Finance byly úspěšně uloženy')

                    changed.value = false

                    nextTick(() => {
                        close()
                    })
                })
                .finally(() => (saving.value = false))
        }

        const remove = () => {
            if (!confirm('Opravdu chcete odebrat záznam?')) {
                return
            }

            if (!item.value.id) return

            deleting.value = true
            odata
                .remove('finance', item.value.id)
                .then(() => {
                    snackbar.info('Záznam byl úspěšně smazán')

                    changed.value = false

                    nextTick(() => {
                        close()
                    })
                })
                .finally(() => (deleting.value = false))
        }

        const openPenezniPolozkaDialog = (typ: string) => {
            if (typ === 'odmenaMes') {
                titlePenezniPolozka.value = 'Odměna měsíc'
                isOdmenaMesic.value = true
                isPokuta.value = false
                isSrazka.value = false
            } else if (typ === 'srazka') {
                titlePenezniPolozka.value = 'Srážka'
                isOdmenaMesic.value = false
                isPokuta.value = false
                isSrazka.value = true
            } else if (typ === 'pokuta') {
                titlePenezniPolozka.value = 'Pokuta'
                isOdmenaMesic.value = false
                isPokuta.value = true
                isSrazka.value = false
            } else {
                return
            }

            if (isOdmenaMesic.value) {
                penezniPolozkas.value = orderBy(
                    (item.value.odmenas ?? []).map((m) => {
                        return {
                            ...m,
                            id: m.id ?? nanoid(10),
                        }
                    }),
                    (o) => o.nazev
                )
            }

            if (isSrazka.value) {
                penezniPolozkas.value = orderBy(
                    (item.value.srazkas ?? []).map((m) => {
                        return {
                            ...m,
                            id: m.id ?? nanoid(10),
                        }
                    }),
                    (o) => o.nazev
                )
            }

            if (isPokuta.value) {
                penezniPolozkas.value = orderBy(
                    (item.value.pokutas ?? []).map((m) => {
                        return {
                            ...m,
                            id: m.id ?? nanoid(10),
                        }
                    }),
                    (o) => o.nazev
                )
            }

            if (!penezniPolozkas.value.length && !props.readonly) {
                addPolozka()
            }

            nextTick(() => {
                changedPenezniPolozka.value = false
                penezniPolozkaDialog.value = true
            })
        }

        const closePenezniPolozkaDialog = () => {
            if (changedPenezniPolozka.value && !confirm('Opravdu chcete zavřít dialog bez uložení dat?')) {
                return
            }

            penezniPolozkaDialog.value = false
        }

        const savePenezniPolozka = () => {
            if (!formPenezniPolozka.value.validate()) {
                return
            }

            if (isOdmenaMesic.value) {
                item.value.odmenas = [...penezniPolozkas.value] as unknown as PenezniPolozka[]
                item.value.odmenaMes = penezniPolozkas.value.filter((f) => !f.isDeleted).reduce((acc, o) => acc + +(o.castka ?? 0), 0)
            }

            if (isSrazka.value) {
                item.value.srazkas = [...penezniPolozkas.value] as unknown as PenezniPolozka[]
                item.value.srazka = penezniPolozkas.value.filter((f) => !f.isDeleted).reduce((acc, o) => acc + +(o.castka ?? 0), 0)
            }

            if (isPokuta.value) {
                item.value.pokutas = [...penezniPolozkas.value] as unknown as PenezniPolozka[]
                item.value.pokuta = penezniPolozkas.value.filter((f) => !f.isDeleted).reduce((acc, o) => acc + +(o.castka ?? 0), 0)
            }

            penezniPolozkaDialog.value = false
        }

        const addPolozka = () => {
            penezniPolozkas.value.push({ id: nanoid(10), isNew: true })
        }

        const delPolozka = (pp: PenezniPolozkaInput) => {
            if (pp.isNew) {
                penezniPolozkas.value = penezniPolozkas.value.filter((o) => o.id !== pp.id)
            } else {
                pp.isDeleted = true
                penezniPolozkas.value = [...penezniPolozkas.value.filter((o) => o.id !== pp.id), pp]
            }
        }

        const close = () => {
            if (changed.value && !confirm('Opravdu chcete zavřít finance bez uložení dat?')) {
                return
            }

            emit('close')
        }

        return {
            item,

            form,
            formPenezniPolozka,
            rules,

            saving,
            deleting,
            changed,

            penezniPolozkaDialog,
            changedPenezniPolozka,

            penezniPolozkas,

            titlePenezniPolozka,

            celkemAgentura,
            celkemFaktura,
            celkemOdmena,
            celkemVyplata,
            celkemZaklad,

            save,
            close,
            remove,

            addPolozka,
            delPolozka,

            openPenezniPolozkaDialog,
            closePenezniPolozkaDialog,
            savePenezniPolozka,
        }
    },
})
