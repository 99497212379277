import Vue from 'vue'
import { VSelect } from 'vuetify/lib'

Vue.component('SSelect', {
    extends: VSelect,
    props: {
        outlined: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: [String, Boolean],
            default: 'auto',
        },
        itemText: {
            type: String,
            default: 'nazev',
        },
        itemValue: {
            type: String,
            default: 'id',
        },
    },
})
