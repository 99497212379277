import '@/components/inputs/select.component'
import '@/components/inputs/text-field.component'

import Vue from 'vue'

import App from './app.vue'
import vuePrototype from './plugins/vue-prototype'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import SNumberField from '@/components/inputs/number-field.component.vue'

import SDatePicker from '@/components/inputs/datepicker.component.vue'
import SMonthPicker from '@/components/inputs/monthpicker.component.vue'

Vue.config.productionTip = false

Vue.use(vuePrototype)

Vue.component('SNumberField', SNumberField)
Vue.component('SDatePicker', SDatePicker)
Vue.component('SMonthPicker', SMonthPicker)

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app')
