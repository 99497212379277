import Vue from 'vue'
import { VTextField } from 'vuetify/lib'

Vue.component('STextField', {
    extends: VTextField,
    props: {
        outlined: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        hideDetails: {
            type: [String, Boolean],
            default: 'auto',
        },
    },
})
