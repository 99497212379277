
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SDatePicker',
    props: {
        value: {
            type: [Date, String],
        },
        label: {
            type: String,
        },
        readonly: {
            type: Boolean,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        required: Boolean,
        rules: {
            default: () => [],
        },
        icon: {
            type: String,
            default: 'mdi-calendar',
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        min: {
            type: String,
        },
    },
    emits: ['input', 'change'],
    setup(props, { emit }) {
        const modal = ref(false)
        const isReadOnly = computed(() => props.readonly)
        const ruleset = computed(() => {
            const result: any[] = [...(props.rules as any[])]
            if (props.required && !isReadOnly.value) result.push((v: any) => !!v || props.label + ' musí být vyplněn')
            return result
        })
        const computerFormat = computed(() => props.value)
        const humanFormat = computed(() => props.value)

        const onClearClicked = () => {
            if (!isReadOnly.value) {
                emit('input', null)
                emit('change', null)
            }
        }

        const onInputChange = (e: any) => {
            emit('input', e)
            emit('change', e)

            modal.value = false
        }

        return {
            modal,
            isReadOnly,
            ruleset,
            computerFormat,
            humanFormat,

            onClearClicked,
            onInputChange,
        }
    },
})
