
import { useStore } from 'vue2-helpers/vuex'

import { Snackbar } from '@/models/snackbar'
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'SSnackbar',
    setup() {
        const store = useStore()
        const snackbar = computed(() => store.getters.snackbar as Snackbar)

        const onClose = (e: boolean) => {
            if (!e) {
                store.commit('HIDE_SNACKBAR')
            }
        }

        return {
            snackbar,

            onClose,
        }
    },
})
