import { RouteConfig } from 'vue-router'

import PracovnikFotoPage from './pages/pracovnik/pracovnik-foto.vue'
import PracovnikDetailPage from './pages/pracovnik/pracovnik.detail.vue'
import PracovnikListPage from './pages/pracovnik/pracovnik.list.vue'
import PracovnikNewPage from './pages/pracovnik/pracovnik.new.vue'
import PracovnikView from './pages/pracovnik/pracovnik.view.vue'

import VykazHodinPage from './pages/pracovnik/pracovnik.vykaz-hodin.vue'

import NastenkaPage from './pages/nastenka.page.vue'

import FinanceList from './pages/finance/finance.list.vue'
import FinanceView from './pages/finance/finance.view.vue'

export const APP_ROUTES: RouteConfig[] = [
    {
        path: '',
        name: 'nastenka.page',
        component: NastenkaPage,
    },
    {
        path: 'pracovnik',
        component: PracovnikView,
        children: [
            {
                path: '',
                name: 'pracovnik-list.page',
                component: PracovnikListPage,
            },
            {
                path: 'new',
                name: 'pracovnik-new.page',
                component: PracovnikNewPage,
            },
            {
                path: ':pracovnikId',
                name: 'pracovnik-detail.page',
                component: PracovnikDetailPage,
                props: true,
            },
            {
                path: '*',
                redirect: { name: 'pracovnik-list.page' },
            },
        ],
    },
    {
        path: 'pracovniks',
        component: PracovnikView,
        children: [
            {
                path: 'foto',
                name: 'pracovnik-foto.page',
                component: PracovnikFotoPage,
            },
            {
                path: 'vykaz-hodin',
                name: 'vykaz-hodin.page',
                component: VykazHodinPage,
            },
        ],
    },
    {
        path: 'finance',
        component: FinanceView,
        children: [
            {
                path: '',
                name: 'finance-list.page',
                component: FinanceList,
            },
            {
                path: 'pracovnik/:pracovnikId',
                name: 'finance.pracovnik-detail.page',
                component: PracovnikDetailPage,
                props: true,
            },
        ],
    },
]
