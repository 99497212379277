
import { Pracovnik } from '@/models/pracovnik'
import { Stredisko } from '@/models/stredisko'
import { ODataService } from '@/services/odata.service'
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    setup() {
        useStore().commit('SET_BREADCRUMPS', [
            { nazev: 'Nástěnka', page: { name: 'nastenka.page' } },
            { nazev: 'Pracovníci', page: { name: 'pracovnik-list.page' } },
            { nazev: 'Foto', page: { name: 'pracovnik-foto.page' } },
        ])

        const odata = new ODataService()
        const strediskos = ref<Stredisko[]>([])
        const pracovniks = ref<Pracovnik[]>([])
        const opens = ref<string[]>([])

        const getPracovniks = (strediskoId: string) => {
            return pracovniks.value.filter((pracovnik) => pracovnik.strediskoId === strediskoId)
        }

        const isOpen = (strediskoId: string) => {
            return opens.value.includes(strediskoId)
        }

        const toggle = (strediskoId: string) => {
            if (isOpen(strediskoId)) {
                opens.value = opens.value.filter((id) => id !== strediskoId)
            } else {
                opens.value.push(strediskoId)
            }
        }

        onMounted(() => {
            odata.getList<Stredisko>('Stredisko', { orderBy: ['cislo', 'nazev'] }).then((data) => {
                strediskos.value = data.data.value
                opens.value = data.data.value.map((stredisko) => stredisko.id)
            })

            odata.getList<Pracovnik>('Pracovnik', { orderBy: ['prijmeni', 'jmeno'], filter: [{ odebranoByznys: { eq: false } }] }).then((data) => {
                pracovniks.value = data.data.value
            })
        })

        return {
            strediskos,
            pracovniks,

            toggle,
            isOpen,
            getPracovniks,
        }
    },
})
