
import { Pracovnik } from '@/models/pracovnik'
import { PracovnikInput } from '@/models/pracovnik.input'
import { Soubor } from '@/models/soubor'
import { Stredisko } from '@/models/stredisko'
import { VForm } from '@/models/vuetify/vform'
import { addDays } from 'date-fns'
import { debounce } from 'lodash'

import { useCheckChanges, useIsAdmin, useSnackbar } from '@/core/composable'
import http from '@/helpers/http'
import { FakturacniSkupina } from '@/models/fakturacni-skupina'
import { Pozice } from '@/models/pozice'
import { Slovnik } from '@/models/slovnik'
import { ODataService } from '@/services/odata.service'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'
import SPracovnikDoklad from './doklad/pracovnik-doklad.vue'
import SPracovnikFinance from './finance/pracovnik-finance.vue'

export default defineComponent({
    name: 'PracovnikDetailPage',
    components: {
        SPracovnikDoklad,
        SPracovnikFinance,
    },
    props: {
        pracovnikId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { changed } = useCheckChanges()
        const snackbar = useSnackbar()
        const { isAdmin } = useIsAdmin()
        const store = useStore()
        const router = useRouter()

        const odata = new ODataService()

        const form = ref({} as VForm)
        const uploadingFoto = ref(false)
        const saving = ref(false)
        const fotka = ref(null as Blob | null)

        const item = ref<PracovnikInput>({} as PracovnikInput)
        const strediskos = ref<Stredisko[]>([])
        const pozices = ref<Pozice[]>([])
        const slovniks = ref<Slovnik[]>([])
        const fakturacniSkupinas = ref<FakturacniSkupina[]>([])

        const rules = {
            jmenoRequired: [(v: string) => !!v || 'Jméno musí být vyplněno'],
            prijmeniRequired: [(v: string) => !!v || 'Příjmení musí být vyplněno'],
            dNarozeniRequired: [(v: Date) => !!v || 'Datum narození musí výt vyplněno'],
            cisloDokladRequired: [(v: string) => !!v || 'Číslo dokladu musí být vyplněno'],
            strediskoRequired: [(v: string) => !!v || 'Středisko musí být vyplněno'],
            dNastupRequired: [(v: Date) => !!v || 'Datum nástupu musí být vyplněno'],
        }

        const { pracovnikId } = props

        const isReadOnly = computed(() => {
            return store.getters.isReadOnly || item.value.odebranoByznys
        })

        const fetchItem = () => {
            if (isAdmin) {
                return odata
                    .getById<Pracovnik>('pracovnik', pracovnikId, {
                        expand: {
                            upravilUzivatel: { select: ['celeJmeno'] },
                            vytvorilUzivatel: { select: ['celeJmeno'] },
                            priznaks: { select: ['slovnikId'] },
                        },
                    })
                    .then((a) => {
                        item.value = { ...(a.data as unknown as PracovnikInput), priznaks: a.data.priznaks.map((m) => m.slovnikId) }
                    })
                    .finally(() => {
                        nextTick(() => (changed.value = false))
                    })
            } else {
                return odata
                    .getById<Pracovnik>('pracovnik', pracovnikId, {
                        expand: { upravilUzivatel: { select: ['celeJmeno'] }, vytvorilUzivatel: { select: ['celeJmeno'] } },
                    })
                    .then((a) => {
                        item.value = { ...(a.data as unknown as PracovnikInput) }
                    })
                    .finally(() => {
                        nextTick(() => (changed.value = false))
                    })
            }
        }

        const vizumDelka = () => {
            if (item.value.vizoDelka && item.value.dVizoZacatek) {
                nextTick(() => {
                    item.value.dVizoKonec = addDays(item.value.dVizoZacatek as Date, +item.value.vizoDelka)
                })
            }
        }

        const vizumDelkaDebounce = debounce(vizumDelka, 1000)

        const obnovit = () => {
            if (confirm('Opravdu obnovit?')) {
                router.push({ name: 'pracovnik-new.page', query: { obnovitPracovnikId: item.value.id } })
            }
        }

        const odebrat = () => {
            if (item.value.id && confirm('Opravdu chcete pracovníka smazat, tato akce je nevratná?')) {
                odata.remove('pracovnik', item.value.id).then(() => {
                    snackbar.success('Pracovník byl smazán')
                    router.push({ name: 'pracovnik-list.page' })
                })
            }
        }

        const save = () => {
            if (!form.value.validate()) return

            saving.value = true

            odata
                .put<Pracovnik>('pracovnik', pracovnikId, item.value, { select: ['id'] })
                .then(() => {
                    snackbar.success('Pracovník byl úspěšně uložen')
                    return fetchItem()
                })
                .finally(() => (saving.value = false))
        }

        var uploadFoto = () => {
            if (fotka.value) {
                uploadingFoto.value = true

                const formData = new FormData()
                formData.append('files', fotka.value)

                http.post('/api/file/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then((a) => {
                        if (a.data) {
                            const soubory = a.data as unknown as Soubor[]
                            item.value.fotkaSouborId = soubory[0].id
                        }

                        fotka.value = null
                    })
                    .finally(() => (uploadingFoto.value = false))
            }
        }

        onMounted(() => {
            odata.getList<Stredisko>('stredisko', { orderBy: ['cislo'] }).then((a) => (strediskos.value = a.data.value))
            odata.getList<Pozice>('pozice', { orderBy: ['nazev'] }).then((a) => (pozices.value = a.data.value))
            odata.getList<FakturacniSkupina>('fakturacniskupina', { orderBy: ['nazev'] }).then((a) => (fakturacniSkupinas.value = a.data.value))

            if (isAdmin) {
                odata.getList<Slovnik>('slovnik', { orderBy: ['zkratka'] }).then((a) => (slovniks.value = a.data.value))
            }
        })

        watch(
            () => pracovnikId,
            (newValue) => {
                if (newValue) {
                    fetchItem()
                }
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        return {
            item,
            strediskos,
            slovniks,
            pozices,
            fakturacniSkupinas,
            form,
            rules,
            saving,
            uploadingFoto,
            fotka,
            changed,
            isReadOnly,

            obnovit,
            odebrat,
            save,
            uploadFoto,
            vizumDelka,
            vizumDelkaDebounce,
        }
    },
})
