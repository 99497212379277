
import { useSnackbar } from '@/core/composable'
import { Pracovnik } from '@/models/pracovnik'
import { ODataService } from '@/services/odata.service'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    name: 'SOdebratByznysNastenka',
    props: {
        pracovnik: {
            type: Object as () => Pracovnik,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const pracovnik = ref<Pracovnik | undefined>()
        const odata = new ODataService()
        const snackbar = useSnackbar()

        const odebrat = () => {
            odata.patch('pracovnik', pracovnik.value?.id || '', { odebranoByznys: true }).then(() => {
                snackbar.success('U pracovníka byl nastav stav: Odebrán z evidence EvPraDo')

                emit('close')
            })
        }

        const hpp = () => {
            odata.patch('pracovnik', pracovnik.value?.id || '', { odebranoByznys: !!pracovnik.value?.dVystup, isHpp: true }).then(() => {
                snackbar.success('Pracovník byl převeden na stav: Hlavní pracovní poměr')

                emit('close')
            })
        }
        const close = () => {
            emit('close')
        }

        watch(
            () => props.pracovnik,
            (newValue) => {
                pracovnik.value = newValue
            },
            { immediate: true }
        )

        return {
            odebrat,
            hpp,
            close,
        }
    },
})
