import Vue from 'vue'
import Vuex from 'vuex'

import { Breadcrump } from '@/models/breadcrump'
import { Snackbar } from '@/models/snackbar'
import { Uzivatel } from '@/models/uzivatel'

Vue.use(Vuex)

export default new Vuex.Store({
    getters: {
        uzivatel(state) {
            return state.uzivatel
        },
        breadcrumps(state) {
            return state.breadcrumps
        },
        snackbar(state) {
            return state.snackbar
        },
        isReadOnly(state) {
            return !!state.uzivatel?.readOnly
        },
    },
    state: {
        uzivatel: null as Uzivatel | null,
        breadcrumps: [] as Breadcrump[],
        snackbar: null as Snackbar | null,
    },
    mutations: {
        SET_UZIVATEL(state, uzivatel) {
            state.uzivatel = uzivatel
        },
        SET_BREADCRUMPS(state, breadcrumps) {
            state.breadcrumps = breadcrumps
        },
        SHOW_SNACKBAR(state, snackbar) {
            state.snackbar = snackbar
        },
        HIDE_SNACKBAR(state) {
            state.snackbar = null
        },
    },
    actions: {},
})
