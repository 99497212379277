
import { useSize, useSnackbar } from '@/core/composable'
import { createQuery } from '@/helpers/create-query'
import { formatDate } from '@/helpers/date-format'
import { generateExcel } from '@/helpers/excel/data-table'
import { unaccent } from '@/helpers/string-format'
import { FakturacniSkupina } from '@/models/fakturacni-skupina'
import { Finance } from '@/models/finance'
import { ODataResult } from '@/models/odata.result'
import { Stredisko } from '@/models/stredisko'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import odata from '@/services/odata.service'
import reportService from '@/services/report.service'
import { debounce } from 'lodash'
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'

export default defineComponent({
    name: 'FinanceList',
    setup() {
        const snackbar = useSnackbar()
        const { height } = useSize()

        const router = useRouter()

        const finances = ref<ODataResult<Finance>>({ value: [], '@odata.count': 0 })
        const filter = ref({
            datum: new Date().getFullYear() + '-' + (new Date().getMonth() + 1),
        } as {
            pracovnikCeleJmeno?: string
            datum: string
            strediskos?: string[]
            fakturacniSkupinas?: string[]
            strediskoCislo?: string
        })

        const strediskos = ref<Stredisko[]>([])
        const fakturacniSkupinas = ref<FakturacniSkupina[]>([])

        const dataLoading = ref(false)
        const exportLoading = ref(false)
        const exportLoadingFaktury = ref(false)
        const exportLoadingSrazka = ref(false)

        const reportLoadingVyplatniPaska = ref(false)

        const datumMenu = ref(false)

        const isUzamceno = ref(false)
        const uzamcenoId = ref('')

        const rok = computed(() => +filter.value.datum!.split('-')[0])
        const mesic = computed(() => +filter.value.datum!.split('-')[1])

        const headers = [
            {
                text: 'Pracovník',
                value: 'pracovnik.celeJmeno',
                divider: true,
                width: 200,
                cellClass: 'freezed',
                class: 'freezed-header',
            },
            {
                text: 'Os. číslo',
                value: 'pracovnik.cisloByznys',
                divider: true,
                width: 100,
            },
            {
                text: 'Středisko',
                value: 'stredisko.celyNazev',
                divider: true,
                width: 150,
            },
            {
                text: 'Fakturační skupina',
                value: 'fakturacniSkupina.nazev',
                divider: true,
                width: 150,
            },
            {
                text: 'Pozice',
                value: 'pozice.nazev',
                divider: true,
                width: 150,
            },
            {
                text: 'Měsíc',
                value: 'datum',
                divider: true,
                width: 130,
                data: (row) => formatDate(row.datum, 'yyyy-MM'),
            },
            {
                text: 'Odpracováno HPP (hod)',
                value: 'odpracovanoHppHod',
                divider: true,
                sortable: false,
                width: 100,
                align: 'end',
                formatNumber: '0.0',
            },
            {
                text: 'Odpracováno Agg. (hod)',
                value: 'odpracovanoHod',
                divider: true,
                sortable: false,
                width: 100,
                align: 'end',
                formatNumber: '0.0',
            },
            {
                text: 'Počet hod po srážce',
                value: 'odpracovanoHodPoSrazce',
                divider: true,
                sortable: false,
                width: 100,
                align: 'end',
                formatNumber: '0.0',
            },
            {
                text: 'Sazba (Kč/hod)',
                value: 'sazbaHod',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Odměna (Kč/hod)',
                value: 'sazbaOdmenaHod',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Odměna měs. (Kč)',
                value: 'odmenaMes',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Stálá fixní odměna (Kč)',
                value: 'odmenaPrac',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Srážka',
                value: 'srazka',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Pokuta',
                value: 'pokuta',
                divider: true,
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Celkem základ (Kč)',
                value: 'celkemZaklad',
                divider: true,
                sortable: false,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Celkem odměna (Kč)',
                value: 'celkemOdmena',
                divider: true,
                sortable: false,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Celkem výplata (Kč)',
                value: 'celkemVyplata',
                divider: true,
                sortable: false,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Celkem faktura (Kč)',
                value: 'celkemFaktura',
                divider: true,
                sortable: false,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Marže agentura (Kč/hod)',
                value: 'sazbaAgenturaHod',
                divider: true,
                sortable: false,
                align: 'end',
                formatNumber: '0.00',
            },
            {
                text: 'Marže agentura (Kč)',
                value: 'celkemAgentura',
                sortable: false,
                width: 70,
                align: 'end',
                formatNumber: '0.00',
            },
        ] as DataTableHeader[]

        const options = ref<DataTableOptions>({
            itemsPerPage: 1_000,
            page: 1,
            sortBy: ['pracovnik.celeJmeno'],
            sortDesc: [false],
            multiSort: true,
            mustSort: false,
        })

        onBeforeMount(() => {
            const tmpFilter = sessionStorage.getItem('finance-list.filter')
            const tmpOptions = sessionStorage.getItem('finance-list.options')

            if (tmpFilter) {
                try {
                    filter.value = JSON.parse(tmpFilter)
                } catch {
                    filter.value.datum = new Date().getFullYear() + '-' + (new Date().getMonth() + 1)
                }
            }

            if (tmpOptions) {
                try {
                    options.value = JSON.parse(tmpOptions)
                } catch {
                    options.value = {
                        itemsPerPage: 1_000,
                        page: 1,
                        sortBy: ['datum', 'pracovnik.celeJmeno'],
                        sortDesc: [true, false],
                        multiSort: true,
                        mustSort: false,
                    }
                }
            }
        })

        onMounted(() => {
            odata.getList<Stredisko>('stredisko', { orderBy: 'cislo' }).then(({ data }) => {
                strediskos.value = data.value
            })

            odata.getList<FakturacniSkupina>('fakturacniskupina', { orderBy: ['nazev'] }).then(({ data }) => {
                fakturacniSkupinas.value = data.value
            })
        })

        const getFilter = () => {
            const _filter: any[] = []

            if ((filter.value.strediskos ?? []).length) _filter.push({ strediskoId: { in: filter.value.strediskos } })
            if ((filter.value.fakturacniSkupinas ?? []).length) _filter.push({ fakturacniSkupinaId: { in: filter.value.fakturacniSkupinas } })
            if (filter.value.pracovnikCeleJmeno) _filter.push({ pracovnik: { normCeleJmeno: { contains: unaccent(filter.value.pracovnikCeleJmeno) } } })
            if (filter.value.datum) _filter.push({ 'year(datum)': { eq: rok.value }, 'month(datum)': { eq: mesic.value } })
            if (filter.value.strediskoCislo) _filter.push({ stredisko: { cislo: { startswith: filter.value.strediskoCislo } } })

            return _filter
        }

        const fetchData = () => {
            dataLoading.value = true

            const tmpQuery = createQuery(options.value)

            odata
                .getList('uzamceno', {
                    select: ['id'],
                    filter: {
                        rok: { eq: rok.value },
                        mesic: { eq: mesic.value },
                    },
                    count: true,
                })
                .then((a) => {
                    if (a.data.value.length) {
                        uzamcenoId.value = a.data.value[0].id
                    }
                    isUzamceno.value = a.data['@odata.count'] > 0
                })

            odata
                .getList<Finance>('finance', {
                    ...tmpQuery,
                    filter: getFilter(),
                    expand: {
                        pracovnik: { select: ['id', 'celeJmeno', 'cisloByznys'] },
                        pozice: { select: ['id', 'nazev'] },
                        stredisko: { select: ['id', 'celyNazev'] },
                        fakturacniSkupina: { select: ['id', 'nazev'] },
                        odmenas: { select: ['id', 'nazev', 'castka'] },
                        srazkas: { select: ['id', 'nazev', 'castka'] },
                        pokutas: { select: ['id', 'nazev', 'castka'] },
                    },
                })
                .then(({ data }) => {
                    finances.value = data
                })
                .finally(() => (dataLoading.value = false))

            sessionStorage.setItem('finance-list.filter', JSON.stringify(filter.value))
            sessionStorage.setItem('finance-list.options', JSON.stringify(options.value))
        }

        const debounceFetchData = debounce(fetchData, 400)

        const open = (f: Finance) => {
            router.push({ name: 'finance.pracovnik-detail.page', params: { pracovnikId: f.pracovnikId } })
        }

        const exportExcel = () => {
            generateExcel('Finance', headers, finances.value.value, { extreLength: 2 })
        }

        const exportExcelFaktury = () => {
            const tmpHeaders = [
                {
                    text: 'Os. číslo',
                    value: 'pracovnik.cisloByznys',
                },
                {
                    text: 'Pracovník',
                    value: 'pracovnik.celeJmeno',
                },
                {
                    text: 'Fakturační skupina',
                    value: 'fakturacniSkupina.nazev',
                },
                {
                    text: 'Měsíc',
                    value: 'datum',
                    divider: true,
                    width: 160,
                    data: (row) => formatDate(row.datum, 'yyyy-MM'),
                },
                {
                    text: 'Celkem faktura (Kč)',
                    value: 'celkemFaktura',
                    formatNumber: '0.00',
                },
            ] as DataTableHeader[]

            generateExcel('Finance - faktury', tmpHeaders, finances.value.value, { extreLength: 2 })
        }

        const exportExcelSrazka = () => {
            const tmpHeaders = [
                {
                    text: 'Pracovník',
                    value: 'pracovnik.celeJmeno',
                },
                {
                    text: 'Středisko',
                    value: 'stredisko.celyNazev',
                },
                {
                    text: 'Měsíc',
                    value: 'datum',
                    data: (row) => formatDate(row.datum, 'yyyy-MM'),
                },
                {
                    text: 'Počet hodin celkem',
                    value: 'odpracovanoHodPoSrazce',
                    formatNumber: '0.0',
                },
                {
                    text: 'Sazba (hod/Kč)',
                    value: 'sazbaHod',
                    formatNumber: '0.00',
                },
                {
                    text: 'Celkem základ (Kč)',
                    value: 'celkemZaklad',
                    formatNumber: '0.00',
                },
                {
                    text: 'Celkem odměna (Kč)',
                    value: 'celkemOdmena',
                    formatNumber: '0.00',
                },
                {
                    text: 'Celkem výplata (Kč)',
                    value: 'celkemVyplata',
                    formatNumber: '0.00',
                },
            ] as DataTableHeader[]

            generateExcel('Finance - agentura', tmpHeaders, finances.value.value, { extreLength: 2 })
        }

        const reportVyplatniPaska = async () => {
            reportLoadingVyplatniPaska.value = true

            reportService
                .vyplatniPaska({
                    rok: rok.value,
                    mesic: mesic.value,
                    strediskos: filter.value.strediskos,
                    fakturacniSkupinas: filter.value.fakturacniSkupinas,
                    pracovnik: filter.value.pracovnikCeleJmeno,
                })
                .then(() => {
                    snackbar.success('Report byl vygenerován')
                    reportLoadingVyplatniPaska.value = false
                })
                .catch(() => {
                    snackbar.error('Nepodařilo se vygenerovat report')
                    reportLoadingVyplatniPaska.value = false
                })
        }

        const uzamknout = () => {
            if (confirm('Opravdu chcete uzamknout měsíc?')) {
                odata
                    .post('uzamceno', {
                        rok: rok.value,
                        mesic: mesic.value,
                    })
                    .then(() => {
                        snackbar.success('Měsíc byl uzamčen')
                        fetchData()
                    })
            }
        }

        const odemknout = () => {
            if (confirm('Opravdu chcete měsíc odemknout')) {
                odata.remove('uzamceno', uzamcenoId.value).then(() => {
                    snackbar.success('Měsic byl odemčen')
                    fetchData()
                })
            }
        }

        return {
            isUzamceno,
            uzamcenoId,
            options,
            headers,
            finances,
            filter,
            strediskos,
            fakturacniSkupinas,

            height,

            dataLoading,
            exportLoading,
            exportLoadingFaktury,
            exportLoadingSrazka,

            datumMenu,

            open,

            fetchData,
            debounceFetchData,
            exportExcel,
            exportExcelFaktury,
            exportExcelSrazka,

            uzamknout,
            odemknout,

            reportVyplatniPaska,
            reportLoadingVyplatniPaska,
        }
    },
})
