
import { useSnackbar } from '@/core/composable'
import { Nastaveni } from '@/models/nastaveni'
import { ODataService } from '@/services/odata.service'
import { debounce } from 'lodash'
import { defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'NastaveniDetail',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [{ nazev: 'Administrace' }, { nazev: 'Finance', page: { name: 'nastaveni-detail.page' } }])

        const odata = new ODataService()
        const snackbar = useSnackbar()

        const nastavenis = ref<Nastaveni[]>([])

        const onChange = (id: string, hodnota: string) => {
            odata.put('nastaveni', id, { hodnota: hodnota.toString() }).then(() => snackbar.success('Nastavení bylo uloženo'))
        }

        onMounted(() => {
            odata.getList<Nastaveni>('nastaveni').then((a) => (nastavenis.value = a.data.value))
        })

        const debounceChange = debounce(onChange, 500)

        return {
            nastavenis,

            debounceChange,
        }
    },
})
