import http from '@/helpers/http'
import { ODataResult } from '@/models/odata.result'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import buildQuery, { QueryOptions } from 'odata-query'

export class ODataService {
    getList<T>(controller: string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<ODataResult<T>>> {
        return http.get('/odata/' + controller + buildQuery(query))
    }

    getById<T>(controller: string, key: string, query?: Partial<QueryOptions<T>>): Promise<AxiosResponse<T>> {
        return http.get('/odata/' + controller + buildQuery({ ...query, key }))
    }

    post<T>(controller: string, data: unknown, query?: Partial<QueryOptions<T>>, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return http.post('/odata/' + controller + buildQuery(query), data, config)
    }

    put<T>(controller: string, key: string, data: unknown, query?: Partial<QueryOptions<T>>, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return http.put(`/odata/${controller}` + buildQuery({ key, ...query }), data, config)
    }

    patch<T>(controller: string, key: string, data: unknown, query?: Partial<QueryOptions<T>>, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return http.patch(`/odata/${controller}` + buildQuery({ key, ...query }), data, config)
    }

    remove(controller: string, key: string): Promise<AxiosResponse> {
        return http.delete(`/odata/${controller}` + buildQuery({ key }))
    }

    function<T>(controller: string, query: Partial<QueryOptions<any>>): Promise<AxiosResponse<T>> {
        return http.get('/odata/' + controller + buildQuery(query))
    }

    action<T>(controller: string, data: never, query: Partial<QueryOptions<any>>): Promise<AxiosResponse<T>> {
        return http.post('/odata/' + controller + buildQuery(query), data)
    }
}

export default new ODataService()
