
import { ODataResult } from '@/models/odata.result'
import { Pracovnik } from '@/models/pracovnik'
import { Doklad } from '@/models/doklad'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import { addDays } from 'date-fns'

import SCisloByznysNastenka from './nastenka/cislo-byznys.vue'
import SOdebratByznysNastenka from './nastenka/odebrat-byznys.vue'

import { ODataService } from '@/services/odata.service'
import { useStore } from 'vue2-helpers/vuex'
import { createQuery } from '@/helpers/create-query'
import { useRouter } from 'vue2-helpers/vue-router'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'NastenkaPage',
    components: {
        SCisloByznysNastenka,
        SOdebratByznysNastenka,
    },
    setup() {
        const store = useStore()
        const odata = new ODataService()
        const router = useRouter()

        const cisloByznysModal = ref(false)
        const odebratByznysModal = ref(false)
        const selectedPracovnik = ref<Pracovnik | null>(null)

        const zmenyDataLoading = ref(false)
        const zmenyData = ref<ODataResult<Pracovnik>>({ value: [], '@odata.count': 0 })
        const zmenyOptions = ref({
            page: 1,
            itemsPerPage: 10,
        } as DataTableOptions)
        const zmenyHeaders = [
            { text: 'Byznys č.', value: 'cisloByznys', divider: true },
            { text: 'Doklad (pas)', value: 'cisloDoklad', divider: true },
            { text: 'Jméno', value: 'celeJmeno', divider: true },
            { text: 'Datum narození', value: 'dNarozeni', divider: true },
            { text: 'Středisko', value: 'stredisko.celyNazev', divider: true },
            { text: 'Datum nástupu', value: 'dNastup', divider: true },
            { text: 'Datum výstupu', value: 'dVystup', divider: true },
            { text: 'Datum přestupu', value: 'dPrestup', divider: true },
            { text: '', value: 'akce', width: 200, sortable: false },
        ] as DataTableHeader[]

        const dokladyDataLoading = ref(false)
        const dokladyData = ref<ODataResult<Doklad>>({ value: [], '@odata.count': 0 })
        const dokladyOptions = ref({
            page: 1,
            itemsPerPage: 10,
            sortBy: ['dKonecPlatnost'],
            sortDesc: [false],
        } as DataTableOptions)
        const dokladyHeaders = [
            { text: 'Byznys č.', value: 'pracovnik.cisloByznys', divider: true },
            { text: 'Jméno', value: 'pracovnik.celeJmeno', divider: true },
            { text: 'Středisko', value: 'pracovnik.stredisko.celyNazev', divider: true },
            { text: 'Název dokladu', value: 'nazev', divider: true },
            { text: 'Doklad č.', value: 'cislo', divider: true },
            { text: 'Konec platnosti', value: 'dKonecPlatnost', divider: true },
        ] as DataTableHeader[]

        const isReadOnly = computed(() => store.getters.isReadOnly)

        store.commit('SET_BREADCRUMPS', [{ nazev: 'Nástěnka', page: { name: 'nastenka.page' } }])

        const getColor = (date: Date) => {
            if (new Date(date) <= addDays(new Date(), 5)) {
                return 'red'
            }

            return 'orange'
        }

        const zmenyFetchData = () => {
            zmenyDataLoading.value = true

            const filter: any[] = []

            filter.push({ or: [{ cisloByznys: { eq: null } }, { cisloByznys: { eq: '' } }] })
            filter.push({
                and: [{ odebranoByznys: { eq: false } }, { or: [{ dVystup: { ne: null } }, { and: [{ dPrestup: { ne: null } }, { isHpp: { eq: false } }] }] }],
            })

            const query = createQuery<Pracovnik>(zmenyOptions.value)

            odata
                .getList<Pracovnik>('pracovnik', {
                    ...query,
                    filter: { or: filter },
                    expand: { stredisko: { select: ['id', 'celyNazev'] } },
                })
                .then((a) => (zmenyData.value = a.data))
                .finally(() => (zmenyDataLoading.value = false))
        }

        const dokladyFetchData = () => {
            dokladyDataLoading.value = true

            const filter: any[] = []
            filter.push({ dKonecPlatnost: { le: addDays(new Date(), 10) } }, { dKonecPlatnost: { gt: addDays(new Date(), -30) } })
            filter.push({
                pracovnik: { odebranoByznys: { eq: false } },
                or: [{ pracovnik: { dVystup: { eq: null } } }, { pracovnik: { dVystup: { gt: new Date() } } }],
            })

            const query = createQuery<Doklad>(dokladyOptions.value)

            odata
                .getList<Doklad>('doklad', {
                    ...query,
                    filter,
                    expand: {
                        pracovnik: {
                            select: ['id', 'celeJmeno', 'cisloByznys'],
                            expand: { stredisko: { select: ['id', 'celyNazev'] } },
                        },
                    },
                })
                .then((a) => (dokladyData.value = a.data))
                .finally(() => (dokladyDataLoading.value = false))
        }

        const otevritDoklad = (doklad: Doklad) => {
            router.push({ name: 'pracovnik-detail.page', params: { pracovnikId: doklad.pracovnikId } })
        }

        const otevritPracovnik = (prac: Pracovnik) => {
            router.push({ name: 'pracovnik-detail.page', params: { pracovnikId: prac.id } })
        }

        const cisloByznysClose = () => {
            cisloByznysModal.value = false
            selectedPracovnik.value = null

            zmenyFetchData()
            dokladyFetchData()
        }

        const setCisloByznys = (item: Pracovnik) => {
            selectedPracovnik.value = item
            cisloByznysModal.value = true
        }

        const odebratByznys = (item: Pracovnik) => {
            selectedPracovnik.value = item
            odebratByznysModal.value = true
        }

        const odebratByznysClose = () => {
            odebratByznysModal.value = false
            selectedPracovnik.value = null
            zmenyFetchData()
        }

        return {
            cisloByznysModal,
            odebratByznysModal,
            selectedPracovnik,
            cisloByznysClose,
            odebratByznys,
            odebratByznysClose,
            setCisloByznys,
            zmenyFetchData,
            isReadOnly,
            zmenyData,
            zmenyDataLoading,
            zmenyOptions,
            zmenyHeaders,
            dokladyFetchData,
            dokladyData,
            dokladyDataLoading,
            dokladyOptions,
            dokladyHeaders,
            otevritDoklad,
            otevritPracovnik,
            getColor,
        }
    },
})
