
import { useCheckChanges, useIsAdmin, useSnackbar } from '@/core/composable'
import http from '@/helpers/http'
import { FakturacniSkupina } from '@/models/fakturacni-skupina'
import { Pozice } from '@/models/pozice'
import { Pracovnik } from '@/models/pracovnik'
import { PracovnikInput } from '@/models/pracovnik.input'
import { Slovnik } from '@/models/slovnik'
import { Soubor } from '@/models/soubor'
import { Stredisko } from '@/models/stredisko'
import { VForm } from '@/models/vuetify/vform'
import odata from '@/services/odata.service'
import { addDays } from 'date-fns'
import { debounce } from 'lodash'
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'PracovnikNewPage',
    setup() {
        const router = useRouter()
        const { isAdmin } = useIsAdmin()
        const snackbar = useSnackbar()
        const { changed } = useCheckChanges()
        const store = useStore()

        const item = ref<PracovnikInput>({
            dVizoKonec: null,
        } as PracovnikInput)
        const uploadingFoto = ref(false)
        const saving = ref(false)
        const fotka = ref(null as Blob | null)
        const strediskos = ref<Stredisko[]>([])
        const slovniks = ref<Slovnik[]>([])
        const pozices = ref<Pozice[]>([])
        const fakturacniSkupinas = ref<FakturacniSkupina[]>([])

        const rules = {
            jmenoRequired: [(v: string) => !!v || 'Jméno musí být vyplněno'],
            prijmeniRequired: [(v: string) => !!v || 'Příjmení musí být vyplněno'],
            dNarozeniRequired: [(v: Date) => !!v || 'Datum narození musí výt vyplněno'],
            cisloDokladRequired: [(v: string) => !!v || 'Číslo dokladu musí být vyplněno'],
            strediskoRequired: [(v: string) => !!v || 'Středisko musí být vyplněno'],
            dNastupRequired: [(v: Date) => !!v || 'Datum nástupu musí být vyplněno'],
        }

        const form = ref({} as VForm)

        const vizumDelka = () => {
            if (item.value.vizoDelka && item.value.dVizoZacatek) {
                nextTick(() => {
                    item.value.dVizoKonec = addDays(item.value.dVizoZacatek as Date, +item.value.vizoDelka)
                })
            }
        }
        const vizumDelkaDebounce = debounce(vizumDelka, 500)

        const save = () => {
            if (!form.value.validate()) return

            saving.value = true

            odata
                .post<Pracovnik>('pracovnik', item.value, { select: ['id'] })
                .then((a) => {
                    changed.value = false
                    snackbar.success('Pracovník byl úspěšně založen')

                    router.push({ name: 'pracovnik-detail.page', params: { pracovnikId: a.data.id } })
                })
                .finally(() => (saving.value = false))
        }

        const uploadFoto = () => {
            if (fotka.value) {
                uploadingFoto.value = true

                const formData = new FormData()
                formData.append('files', fotka.value)

                http.post('/api/file/upload', formData, { headers: { 'Content-Type': 'multipark/form-data' } })
                    .then((a) => {
                        if (a.data) {
                            const soubory = a.data as unknown as Soubor[]
                            item.value.fotkaSouborId = soubory[0].id
                        }

                        fotka.value = null
                    })
                    .finally(() => (uploadingFoto.value = false))
            }
        }

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        watch(
            () => router.currentRoute.query.obnovitPracovnikId,
            (newValue) => {
                if (newValue) {
                    odata
                        .getById<Pracovnik>('pracovnik', newValue as string, { expand: { priznaks: { select: ['slovnikId'] } } })
                        .then((a) => {
                            item.value = {
                                ...(a.data as unknown as PracovnikInput),
                                id: undefined,
                                cisloDoklad: null,
                                cisloByznys: null,
                                odebranoByznys: false,
                                dNastup: null,
                                dPrestup: null,
                                dVystup: null,
                                dVizoZacatek: null,
                                dVizoKonec: null,
                                isHpp: false,
                                priznaks: a.data.priznaks.map((m) => m.slovnikId),
                            }
                        })
                        .finally(() => {
                            nextTick(() => form.value.resetValidation())
                        })
                }
            },
            { immediate: true }
        )

        onMounted(() => {
            if (store.getters.isReadOnly) {
                router.push({ name: 'pracovnik-list.page' })
            }

            odata.getList<Stredisko>('stredisko', { orderBy: ['cislo'] }).then((a) => (strediskos.value = a.data.value))
            odata.getList<Pozice>('pozice', { orderBy: ['nazev'] }).then((a) => (pozices.value = a.data.value))
            odata.getList<FakturacniSkupina>('fakturacniskupina', { orderBy: ['nazev'] }).then((a) => (fakturacniSkupinas.value = a.data.value))

            if (isAdmin) {
                odata.getList<Slovnik>('slovnik', { orderBy: ['zkratka'] }).then((a) => (slovniks.value = a.data.value))
            }
        })

        return {
            item,
            form,
            rules,
            strediskos,
            slovniks,
            pozices,
            fakturacniSkupinas,
            fotka,
            uploadingFoto,
            saving,
            changed,

            uploadFoto,
            save,
            vizumDelka,
            vizumDelkaDebounce,
        }
    },
})
