import http from '@/helpers/http'

import { createQuery } from '@/helpers/create-query'
import { formatDate } from '@/helpers/date-format'
import { formatNumber } from '@/helpers/number-format'
import { unaccent } from '@/helpers/string-format'

import { Role } from '@/models/role'
import { ODataService } from '@/services/odata.service'
import store from '@/store'

const vuePrototype = {
    install: (Vue: any): void => {
        Vue.prototype.$odata = new ODataService()

        Vue.prototype.$formatDate = formatDate
        Vue.prototype.$formatNumber = formatNumber
        Vue.prototype.$unaccent = unaccent

        Vue.prototype.$createQuery = createQuery

        Vue.prototype.$http = http

        Vue.prototype.$isAdmin = () => {
            return !!store.getters.uzivatel?.uzivatelRoles?.map((m: any) => m.role)?.find((f: Role) => f.name === 'ADMIN')
        }

        Vue.prototype.$isFinance = () => {
            return !!store.getters.uzivatel?.uzivatelRoles?.map((m: any) => m.role)?.find((f: Role) => f.name === 'FINANCE')
        }

        Vue.prototype.$isManagerFinance = () => {
            return !!store.getters.uzivatel?.uzivatelRoles?.map((m: any) => m.role)?.find((f: Role) => f.name === 'MANAGER_FINANCE')
        }
    },
}

export default vuePrototype
