
import { defineComponent } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'FakturacniSkupinaView',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [{ nazev: 'Administrace' }, { nazev: 'Fakturační skupina', page: { name: 'fakturacni-skupina-list.page' } }])
    },
})
