import { QueryOptions } from 'odata-query'
import { DataTableOptions } from '../models/vuetify/datatable/datatable.options'

export function createQuery<T>(options: DataTableOptions): Partial<QueryOptions<T>> {
    const skip = (options.page - 1) * options.itemsPerPage
    const top = options.itemsPerPage > 0 ? options.itemsPerPage : 100

    const orderBy = options.sortBy.map((sort, index) => sort.replace('.', '/') + (options.sortDesc[index] ? ' desc' : '')) as any

    return {
        skip,
        orderBy,
        top,
        count: true,
    }
}
