
import { defineComponent } from 'vue'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'SlovnikView',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [{ nazev: 'Administrace' }, { nazev: 'Příznaky', page: { name: 'slovnik-list.page' } }])
    },
})
