
import { debounce } from 'lodash'

import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import { ODataResult } from '@/models/odata.result'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'

import { Uzivatel } from '@/models/uzivatel'
import { Role } from '@/models/role'
import { Stredisko } from '@/models/stredisko'
import { useRouter } from 'vue2-helpers/vue-router'
import { ODataService } from '@/services/odata.service'
import { createQuery } from '@/helpers/create-query'
import { unaccent } from '@/helpers/string-format'
import { useStore } from 'vue2-helpers/vuex'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'

export default defineComponent({
    name: 'UzivatelListPage',
    setup() {
        const store = useStore()
        const router = useRouter()
        const odata = new ODataService()
        const filter = reactive({
            prijmeni: '',
            jmeno: '',
            strediskos: [],
            roles: [],
        })
        const roles = ref<Role[]>([])
        const strediskos = ref<Stredisko[]>([])
        const options = ref<DataTableOptions>({
            page: 1,
            itemsPerPage: 20,
            sortBy: ['prijmeni', 'jmeno'],
            sortDesc: [false, false],
            multiSort: true,
        })
        const dataLoading = ref(false)
        const data = ref<ODataResult<Uzivatel>>({ value: [], '@odata.count': 0 })
        const headers = [
            { text: 'Příjmení', value: 'prijmeni', divider: true },
            { text: 'Jméno', value: 'jmeno', divider: true },
            { text: 'Střediska', value: 'stredisko', divider: true, sortable: false },
            { text: 'Role', value: 'role', sortable: false },
        ] as DataTableHeader[]

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const fetchData = () => {
            dataLoading.value = true

            var query = createQuery(options.value)

            const qFilter: any[] = []
            if (filter['prijmeni']) {
                qFilter.push({ normPrijmeni: { contains: unaccent(filter['prijmeni']) } })
            }

            if (filter['jmeno']) {
                qFilter.push({ normJmeno: { contains: unaccent(filter['jmeno']) } })
            }

            if (filter['strediskos'] && filter['strediskos'].length) {
                qFilter.push({ uzivatelStrediskos: { any: { strediskoId: { in: filter['strediskos'] } } } })
            }

            if (filter['roles'] && filter['roles'].length) {
                qFilter.push({ uzivatelRoles: { any: { roleId: { in: filter['roles'] } } } })
            }

            odata
                .getList<Uzivatel>('uzivatel', {
                    ...query,
                    select: ['id', 'prijmeni', 'jmeno'],
                    expand: {
                        uzivatelStrediskos: { select: ['id', 'strediskoId'], expand: { stredisko: { select: ['id', 'cislo', 'nazev'] } } },
                        uzivatelRoles: { select: ['id', 'roleId'], expand: { role: { select: ['id', 'nazev'] } } },
                    },
                    filter: qFilter,
                })
                .then((a) => (data.value = a.data))
                .finally(() => (dataLoading.value = false))
        }

        const debounceFetchData = debounce(fetchData, 500)

        const open = (e: Uzivatel) => {
            router.push({ name: 'uzivatel-detail.page', params: { uzivatelId: e.id } })
        }

        onMounted(() => {
            odata.getList<Role>('role', { orderBy: ['nazev'] }).then((a) => (roles.value = a.data.value))
            odata.getList<Stredisko>('stredisko', { orderBy: ['cislo'] }).then((a) => (strediskos.value = a.data.value))
        })

        return {
            filter,
            roles,
            strediskos,
            options,
            data,
            dataLoading,
            headers,
            isReadOnly,

            debounceFetchData,
            fetchData,
            open,
        }
    },
    watch: {
        '$vuetify.breakpoint.smAndUp': {
            immediate: true,
            handler(newValue) {
                this.options.page = 1
                this.options.itemsPerPage = newValue ? 20 : 10

                this.filter.prijmeni = ''
                this.filter.jmeno = ''
                this.filter.strediskos = []
                this.filter.roles = []

                this.fetchData()
            },
        },
    },
})
