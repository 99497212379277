import { formatDate } from '@/helpers/date-format'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'

import { get } from 'lodash'

import { exportExcel, ISettings } from '../export-excel'

export function generateExcel(name: string, headers: DataTableHeader[], data: any[], setting?: ISettings) {
    const exportData = [
        {
            sheet: name,
            columns: [
                ...headers.map((m: DataTableHeader) => {
                    return { label: m.text, value: (row: any) => getData(m, row), formatNumber: m.formatNumber }
                }),
            ],
            content: [...data],
        },
    ]

    const settings: ISettings = {
        fileName: name,
        extreLength: setting?.extreLength,
        writeOptions: setting?.writeOptions ?? {},
    }

    exportExcel(exportData as any, settings)
}

function getData(header: DataTableHeader, row: any) {
    if (header.data) {
        return header.data(row)
    }

    const result = get(row, header.value)

    if (result && header.date) {
        if (typeof header.date === 'string') {
            return formatDate(result, header.date)
        }

        return formatDate(result)
    }

    if (header.negation) return !result ? 'ANO' : 'NE'
    if (header.bool) return result ? 'ANO' : 'NE'

    return result
}
