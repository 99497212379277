import { useStore } from 'vue2-helpers/vuex'

import { Snackbar } from '@/models/snackbar'

export function useSnackbar() {
    const store = useStore()

    const success = (text: string) => {
        store.commit('SHOW_SNACKBAR', { text, color: 'success' })
    }

    const info = (text: string) => {
        store.commit('SHOW_SNACKBAR', { text, color: 'info' })
    }

    const warn = (text: string) => {
        store.commit('SHOW_SNACKBAR', { text, color: 'warning' })
    }

    const error = (text: string) => {
        store.commit('SHOW_SNACKBAR', { text, color: 'error' })
    }

    const showSnackbar = (snackbar: Snackbar) => {
        store.commit('SHOW_SNACKBAR', snackbar)
    }

    return {
        success,
        info,
        warn,
        error,
        showSnackbar,
    }
}
