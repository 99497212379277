
import { useIsFinance, useSize, useSnackbar } from '@/core/composable'
import { createQuery } from '@/helpers/create-query'
import { formatDate } from '@/helpers/date-format'
import { unaccent } from '@/helpers/string-format'
import { FakturacniSkupina } from '@/models/fakturacni-skupina'
import { Finance } from '@/models/finance'
import { Nastaveni } from '@/models/nastaveni'
import { Pozice } from '@/models/pozice'
import { Pracovnik } from '@/models/pracovnik'
import { Stredisko } from '@/models/stredisko'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import odata from '@/services/odata.service'
import { debounce } from 'lodash'
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, ref } from 'vue'
import { useStore } from 'vue2-helpers/vuex'
import FinanceDialog from './finance/finance-dialog.vue'

export default defineComponent({
    components: { FinanceDialog },
    setup() {
        const store = useStore()
        store.commit('SET_BREADCRUMPS', [
            { nazev: 'Nástěnka', page: { name: 'nastenka.page' } },
            { nazev: 'Výkaz hodin', page: { name: 'vykaz-hodin.page' } },
        ])

        const isUzamceno = ref(false)
        const uzamcenoId = ref('')

        const modal = ref(false)
        const { height } = useSize()
        const { isFinance } = useIsFinance()
        const snackbar = useSnackbar()
        const headers = [
            {
                text: 'Os. číslo',
                value: 'cisloByznys',
                divider: true,
            },
            {
                text: 'Pracovník',
                value: 'celeJmeno',
                divider: true,
            },
            {
                text: 'Středisko',
                value: 'stredisko.celyNazev',
                divider: true,
            },
            {
                text: 'Pozice',
                value: 'pozice.nazev',
                divider: true,
            },
            {
                text: 'Fakturační skupina',
                value: 'fakturacniSkupina.nazev',
                divider: true,
            },
            {
                text: 'Odpracováno HPP (hod)',
                value: 'odpracovanoHppHod',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Odpracováno Agg. (hod)',
                value: 'odpracovanoHod',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Odměna (Kč/hod)',
                value: 'sazbaOdmenaHod',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Odměna měs. (Kč)',
                value: 'odmenaMes',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Stálá fixní odměna (Kč)',
                value: 'odmenaPrac',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Srážka',
                value: 'srazka',
                sortable: false,
                divider: true,
                align: 'end',
            },
            {
                text: 'Pokuta',
                value: 'pokuta',
                sortable: false,
                divider: true,
                align: 'end',
            },
        ] as DataTableHeader[]

        const data = ref([] as any[])
        const dataLoading = ref(false)
        const filter = ref({
            pozices: [],
            strediskos: [],
            fakturacniSkupinas: [],
            celeJmeno: '',
            cisloByznys: '',
            datum: new Date().getFullYear() + '-' + new Date().getMonth(),
        })
        const strediskos = ref([] as Stredisko[])
        const pozices = ref([] as Pozice[])
        const fakturacniSkupinas = ref([] as FakturacniSkupina[])

        const rok = computed(() => +filter.value.datum.split('-')[0])
        const mesic = computed(() => +filter.value.datum.split('-')[1])

        const item = ref({} as any)

        onBeforeMount(() => {
            const tmpFilter = sessionStorage.getItem('vykaz-hodin.filter')
            const tmpOptions = sessionStorage.getItem('vykaz-hodin.options')

            if (tmpFilter) {
                try {
                    filter.value = JSON.parse(tmpFilter)
                } catch {
                    filter.value.datum = new Date().getFullYear() + '-' + new Date().getMonth()
                }
            }

            if (tmpOptions) {
                try {
                    options.value = JSON.parse(tmpOptions)
                } catch {
                    options.value = {
                        page: 1,
                        itemsPerPage: 10_000_000,
                        multiSort: true,
                        sortBy: ['celeJmeno'],
                        sortDesc: [false],
                    }
                }
            }
        })

        onMounted(() => {
            odata.getList<Stredisko>('stredisko', { select: ['id', 'celyNazev'], orderBy: ['celyNazev'] }).then(({ data }) => (strediskos.value = data.value))
            odata.getList<Pozice>('pozice', { select: ['id', 'nazev'], orderBy: ['nazev'] }).then(({ data }) => (pozices.value = data.value))
            odata.getList<FakturacniSkupina>('fakturacniSkupina', { orderBy: ['nazev'] }).then(({ data }) => (fakturacniSkupinas.value = data.value))
            fetchData()
        })

        const fetchData = () => {
            dataLoading.value = true
            const query = createQuery(options.value)
            delete query.top
            delete query.skip

            var or1 = {
                or: [
                    { and: [{ 'year(dNastup)': { eq: rok.value } }, { 'month(dNastup)': { eq: mesic.value } }] },
                    { dNastup: { lt: new Date(rok.value, mesic.value - 1, 1) } },
                ],
            }
            var or2 = {
                or: [{ dVystup: { eq: null } }, { dVystup: { ge: new Date(rok.value, mesic.value - 1, 1) } }],
            }

            const tmpFilter = []
            if (filter.value.pozices?.length > 0) tmpFilter.push({ poziceId: { in: filter.value.pozices } })
            if (filter.value.strediskos?.length > 0) tmpFilter.push({ strediskoId: { in: filter.value.strediskos } })
            if (filter.value.fakturacniSkupinas?.length > 0) tmpFilter.push({ fakturacniSkupinaId: { in: filter.value.fakturacniSkupinas } })
            if (filter.value.celeJmeno) tmpFilter.push({ normCeleJmeno: { contains: unaccent(filter.value.celeJmeno) } })
            if (filter.value.cisloByznys) tmpFilter.push({ cisloByznys: { contains: filter.value.cisloByznys } })

            const pracovniks = odata.getList<Pracovnik>('pracovnik', {
                ...query,
                select: [
                    'id',
                    'celeJmeno',
                    'cisloByznys',
                    'strediskoId',
                    'stredisko',
                    'poziceId',
                    'pozice',
                    'fakturacniSkupinaId',
                    'fakturacniSkupina',
                    'isHpp',
                    'sazbaOdmenaHod',
                    'odmenaPrac',
                ],
                filter: {
                    and: [...tmpFilter, or1, or2],
                },
                expand: {
                    stredisko: { select: ['id', 'celyNazev'] },
                    pozice: { select: ['id', 'nazev'] },
                    fakturacniSkupina: { select: ['id', 'nazev'] },
                },
            })

            const finances = odata.getList<Finance>('finance', {
                select: ['id', 'odpracovanoHod', 'odpracovanoHppHod', 'sazbaOdmenaHod', 'srazka', 'pokuta', 'pracovnikId', 'odmenaMes', 'odmenaPrac'],
                expand: {
                    odmenas: { select: ['id', 'nazev', 'castka'] },
                    srazkas: { select: ['id', 'nazev', 'castka'] },
                    pokutas: { select: ['id', 'nazev', 'castka'] },
                },
                filter: {
                    'year(datum)': { eq: rok.value },
                    'month(datum)': { eq: mesic.value },
                },
            })

            odata
                .getList('uzamceno', {
                    select: ['id'],
                    filter: {
                        rok: { eq: rok.value },
                        mesic: { eq: mesic.value },
                    },
                    count: true,
                })
                .then((a) => {
                    if (a.data.value.length) {
                        uzamcenoId.value = a.data.value[0].id
                    }

                    isUzamceno.value = a.data['@odata.count'] > 0 || rok.value < 2022 || (rok.value == 2022 && mesic.value < 9)
                })

            Promise.all([pracovniks, finances])
                .then(([prac, finc]) => {
                    const res = [] as any[]
                    prac.data.value.forEach((p) => {
                        const f = finc.data.value.find((f) => f.pracovnikId === p.id)
                        if (!f) {
                            res.push({ ...p })
                            return
                        }
                        const fId = f!.id
                        delete f!.id
                        res.push({ ...p, ...f, financeId: fId })
                    })
                    data.value = res
                })
                .finally(() => (dataLoading.value = false))

            sessionStorage.setItem('vykaz-hodin.filter', JSON.stringify(filter.value))
            sessionStorage.setItem('vykat-hodin.options', JSON.stringify(options.value))
        }

        const debounceFetchData = debounce(fetchData, 500)
        const options = ref<DataTableOptions>({
            page: 1,
            itemsPerPage: 10_000_000,
            multiSort: true,
            sortBy: ['celeJmeno'],
            sortDesc: [false],
        })

        const onClose = () => {
            modal.value = false

            fetchData()
        }

        const open = async (e: any) => {
            if (e.financeId) {
                const tmp = {
                    ...(
                        await odata.getById<Finance>('finance', e.financeId, {
                            select: [
                                'id',
                                'datum',
                                'isHpp',
                                'sazbaOdmenaHod',
                                'odmenaMes',
                                'odmenaPrac',
                                'odpracovanoHod',
                                'odpracovanoHppHod',
                                'srazka',
                                'pokuta',
                                ...((isFinance ? ['sazbaHod', 'sazbaAgenturaHod', 'celkemVyplata', 'celkemAgentura'] : []) as any),
                            ],
                            expand: {
                                odmenas: { select: ['id', 'nazev', 'castka'] },
                                srazkas: { select: ['id', 'nazev', 'castka'] },
                                pokutas: { select: ['id', 'nazev', 'castka'] },
                            },
                        })
                    ).data,
                }
                item.value = { ...tmp, datum: formatDate(tmp.datum, 'yyyy-MM') }
            } else {
                if (!e.poziceId) {
                    snackbar.warn('Není vyplněná pozice')
                    return
                }

                if (!e.fakturacniSkupinaId) {
                    snackbar.warn('Fakturační skupina musí být vyplněna')
                    return
                }

                if (!e.strediskoId) {
                    snackbar.warn('Středisko musí být vyplněno')
                    return
                }

                if (isUzamceno.value) {
                    snackbar.info('Měsíc je již uzavřen')
                    return
                }

                let pozice: Pozice | null = null
                let marzeAgentura: string | null = null
                if (isFinance) {
                    pozice = (await odata.getById<Pozice>('pozice', e.poziceId)).data
                    marzeAgentura = (await odata.getList<Nastaveni>('nastaveni', { filter: { klic: { eq: 'AGENTURA_MARZE' } } })).data.value[0].hodnota
                }

                item.value = {
                    datum: filter.value.datum,
                    isHpp: e.isHpp,
                    pracovnikId: e.id,
                    sazbaHod: pozice?.sazba,
                    poziceId: e.poziceId,
                    strediskoId: e.strediskoId,
                    fakturacniSkupinaId: e.fakturacniSkupinaId,
                    sazbaAgenturaHod: marzeAgentura,
                    sazbaOdmenaHod: e.sazbaOdmenaHod,
                    odmenaPrac: e.odmenaPrac,
                    odmenaMesic: 0,
                    srazka: 0,
                    pokuta: 0,
                } as any
            }

            nextTick(() => {
                modal.value = true
            })
        }

        const isReadOnly = computed(() => store.getters.readonly || isUzamceno.value)

        const uzamknout = () => {
            if (confirm('Opravdu chcete uzamknout měsíc?')) {
                odata
                    .post('uzamceno', {
                        rok: rok.value,
                        mesic: mesic.value,
                    })
                    .then(() => {
                        snackbar.success('Měsíc byl uzamčen')
                        fetchData()
                    })
            }
        }

        const odemknout = () => {
            if (confirm('Opravdu chcete měsíc odemknout')) {
                odata.remove('uzamceno', uzamcenoId.value).then(() => fetchData())
            }
        }

        return {
            item,
            modal,
            isReadOnly,
            isUzamceno,
            uzamcenoId,

            headers,

            data,
            dataLoading,

            filter,

            height,

            strediskos,
            pozices,
            fakturacniSkupinas,

            options,
            open,
            fetchData,
            debounceFetchData,
            onClose,
            uzamknout,
            odemknout,
        }
    },
})
