
import { Role } from '@/models/role'
import { Stredisko } from '@/models/stredisko'
import { Uzivatel, UzivatelInput } from '@/models/uzivatel'
import { VForm } from '@/models/vuetify/vform'
import { useRouter } from 'vue2-helpers/vue-router'
import { useCheckChanges, useSnackbar } from '@/core/composable'
import { ODataService } from '@/services/odata.service'
import { useStore } from 'vue2-helpers/vuex'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
    name: 'UzivatelDetailPage',
    props: {
        uzivatelId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter()
        const odata = new ODataService()
        const snackbar = useSnackbar()
        const { changed } = useCheckChanges()
        const store = useStore()

        const saving = ref(false)
        const item = ref<UzivatelInput>({ zmenitHeslo: true } as UzivatelInput)
        const roles = ref<Role[]>([])
        const strediskos = ref<Stredisko[]>([])

        const form = ref({} as VForm)

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const { uzivatelId } = props

        const rules = {
            jmenoRules: [(r: string) => !!r || 'Jméno musí být vyplněno'],
            prijmeniRules: [(r: string) => !!r || 'Příjmení musí být vyplěno'],
            userNameRules: [(r: string) => !!r || 'Uživatelské jméno musí být vyplněno'],
            hesloKontrolaRules: [(r: string) => !!uzivatelId || item.value.heslo === r || 'Hesla se musí schodovat'],
            hesloRules: [
                (v: string) => !!uzivatelId || !!v || 'Heslo musí být vyplněno',
                (v: string) => !!uzivatelId || (v ?? '').length >= 4 || 'Heslo musí mít minimálně 4 znaky',
            ],
        }

        const fetchItem = () => {
            if (uzivatelId) {
                odata
                    .getById<Uzivatel>('uzivatel', uzivatelId, {
                        expand: {
                            vytvorilUzivatel: { select: ['celeJmeno'] },
                            upravilUzivatel: { select: ['celeJmeno'] },
                            uzivatelRoles: { select: ['roleId'] },
                            uzivatelStrediskos: { select: ['strediskoId'] },
                        },
                    })
                    .then(
                        (a) =>
                            (item.value = {
                                ...(a.data as any),
                                strediskoIds: a.data.uzivatelStrediskos.map((m) => m.strediskoId),
                                roleIds: a.data.uzivatelRoles.map((m) => m.roleId),
                            })
                    )
                    .finally(() => (changed.value = false))
            }
        }

        onMounted(() => {
            odata.getList<Role>('role', { orderBy: ['nazev'] }).then((a) => (roles.value = a.data.value))
            odata.getList<Stredisko>('stredisko', { orderBy: ['cislo', 'nazev'] }).then((a) => (strediskos.value = a.data.value))
        })

        const create = () => {
            return odata.post('uzivatel', item.value)
        }

        const update = () => {
            return odata.put('uzivatel', uzivatelId, item.value)
        }

        const save = () => {
            saving.value = true

            const promise = uzivatelId ? update() : create()
            promise
                .then(() => {
                    snackbar.success('Uživatel byl uložen')
                    changed.value = false

                    router.push({ name: 'uzivatel-list.page' })
                })
                .catch(() => snackbar.error('Uživatel se nepodařil uložit'))
                .finally(() => (saving.value = false))
        }

        watch(
            () => uzivatelId,
            (newValue) => {
                if (newValue) {
                    fetchItem()
                } else {
                    item.value = { zmenitHeslo: true } as UzivatelInput
                }
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        return {
            item,
            roles,
            strediskos,
            form,
            rules,
            saving,
            changed,
            isReadOnly,

            save,
        }
    },
})
