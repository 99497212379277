
import { useStore } from 'vue2-helpers/vuex'
import { useRouter } from 'vue2-helpers/vue-router'

import SAppBar from './app-bar.vue'
import SAppMenu from './app-menu.vue'
import SAppFooter from './app-footer.vue'
import SSnackbar from '@/components/snackbar.component.vue'

import { Uzivatel } from '@/models/uzivatel'
import { ODataService } from '@/services/odata.service'
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
    name: 'SAppLayout',
    components: {
        's-app-bar': SAppBar,
        's-app-menu': SAppMenu,
        's-app-footer': SAppFooter,
        's-snackbar': SSnackbar,
    },
    setup() {
        const drawer = ref(true)
        const router = useRouter()
        const store = useStore()
        const mini = ref(false)

        const odata = new ODataService()
        onMounted(() => {
            odata
                .function<Uzivatel>('uzivatel', {
                    func: 'CurrentUser()',
                    select: ['id', 'celeJmeno', 'zmenitHeslo', 'readOnly'],
                    expand: { uzivatelRoles: { expand: { role: {} } } },
                })
                .then((a) => {
                    store.commit('SET_UZIVATEL', a.data)
                    if (a.data.zmenitHeslo) {
                        router.push({ name: 'zmena-hesla.page' })
                    }
                })
        })

        const onMiniVariant = () => {
            mini.value = true
        }

        return {
            drawer,
            mini,
            onMiniVariant,
        }
    },
})
