import { RouteConfig } from 'vue-router'

import UzivatelDetailPage from './pages/uzivatel/uzivatel.detail.vue'
import UzivatelListPage from './pages/uzivatel/uzivatel.list.vue'
import UzivatelView from './pages/uzivatel/uzivatel.view.vue'

import StrediskoDetailPage from './pages/stredisko/stredisko.detail.vue'
import StrediskoListPage from './pages/stredisko/stredisko.list.vue'
import StrediskoView from './pages/stredisko/stredisko.view.vue'

import SlovnikDetailPage from './pages/slovnik/slovnik.detail.vue'
import SlovnikListPage from './pages/slovnik/slovnik.list.vue'
import SlovnikView from './pages/slovnik/slovnik.view.vue'

import PoziceDetailPage from './pages/pozice/pozice.detail.vue'
import PoziceListPage from './pages/pozice/pozice.list.vue'
import PoziceView from './pages/pozice/pozice.view.vue'

import FakturacniSkupinaDetailPage from './pages/fakturacni-skupina/fakturacni-skupina.detail.vue'
import FakturacniSkupinaListPage from './pages/fakturacni-skupina/fakturacni-skupina.list.vue'
import FakturacniSkupinaView from './pages/fakturacni-skupina/fakturacni-skupina.view.vue'

import NastaveniDetailPage from './pages/nastaveni/nastaveni.detail.vue'

export const ADMIN_ROUTES: Array<RouteConfig> = [
    {
        path: 'uzivatel',
        component: UzivatelView,
        children: [
            { path: '', name: 'uzivatel-list.page', component: UzivatelListPage },
            { path: 'new', name: 'uzivatel-new.page', component: UzivatelDetailPage },
            { path: ':uzivatelId', name: 'uzivatel-detail.page', component: UzivatelDetailPage, props: true },
            { path: '*', redirect: { name: 'uzivatel-list.page' } },
        ],
    },
    {
        path: 'stredisko',
        component: StrediskoView,
        children: [
            { path: '', name: 'stredisko-list.page', component: StrediskoListPage },
            { path: 'new', name: 'stredisko-new.page', component: StrediskoDetailPage },
            { path: ':strediskoId', name: 'stredisko-detail.page', component: StrediskoDetailPage, props: true },
            { path: '*', redirect: { name: 'stredisko-list.page' } },
        ],
    },
    {
        path: 'slovnik',
        component: SlovnikView,
        children: [
            { path: '', name: 'slovnik-list.page', component: SlovnikListPage },
            { path: 'new', name: 'slovnik-new.page', component: SlovnikDetailPage },
            { path: ':slovnikId', name: 'slovnik-detail.page', component: SlovnikDetailPage, props: true },
            { path: '*', redirect: { name: 'slovnik-list.page' } },
        ],
    },
    {
        path: 'pozice',
        component: PoziceView,
        children: [
            { path: '', name: 'pozice-list.page', component: PoziceListPage },
            { path: 'new', name: 'pozice-new.page', component: PoziceDetailPage },
            { path: ':poziceId', name: 'pozice-detail.page', component: PoziceDetailPage, props: true },
            { path: '*', redirect: { name: 'pozice-list.page' } },
        ],
    },
    {
        path: 'fakturacni-skupina',
        component: FakturacniSkupinaView,
        children: [
            { path: '', name: 'fakturacni-skupina-list.page', component: FakturacniSkupinaListPage },
            { path: 'new', name: 'fakturacni-skupina-new.page', component: FakturacniSkupinaDetailPage },
            { path: ':fSkupinaId', name: 'fakturacni-skupina-detail.page', component: FakturacniSkupinaDetailPage, props: true },
            { path: '*', redirect: { name: 'fakturacni-skupina-list.page' } },
        ],
    },
    {
        path: 'nastaveni',
        component: NastaveniDetailPage,
        name: 'nastaveni-detail.page',
    },
]
