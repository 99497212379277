
import { useRouter } from 'vue2-helpers/vue-router'

import { VForm } from '@/models/vuetify/vform'
import http from '@/helpers/http'
import { AxiosError } from 'axios'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
    name: 'LoginPage',
    setup() {
        const router = useRouter()

        const form = ref({} as VForm)

        const valid = ref(true)
        const isLoading = ref(false)
        const errorMessage = ref('')

        const userName = ref('')
        const password = ref('')

        const userNamePh = ref('')
        const passwordPh = ref('')

        const userNameRules = [(v: string) => !!v || 'Uživatelské jméno je povinné']
        const passwordRules = [(v: string) => !!v || 'Heslo musí být vyplněno']

        watch(
            [userName, password],
            (newValue) => {
                userNamePh.value = newValue[0] ? ' ' : ''
                passwordPh.value = newValue[1] ? ' ' : ''
            },
            { immediate: true }
        )

        const submit = async () => {
            if (!form.value.validate()) return

            isLoading.value = true

            http.post('/api/auth/login', { userName: userName.value, password: password.value })
                .then(() => {
                    router.push('/')
                })
                .catch((e: AxiosError) => {
                    if (e.response?.status === 401) {
                        errorMessage.value = 'Špatné uživatelské jméno nebo heslo'
                    } else {
                        errorMessage.value = 'Problém s připojením k serveru'
                    }
                })
                .finally(() => {
                    isLoading.value = false
                })
        }

        return {
            form,
            valid,
            isLoading,
            errorMessage,
            userName,
            password,
            userNamePh,
            passwordPh,

            userNameRules,
            passwordRules,

            submit,
        }
    },
})
