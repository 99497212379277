
import { DokladInput } from '@/models/doklad.input'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { VForm } from '@/models/vuetify/vform'
import { Soubor } from '@/models/soubor'
import { Doklad } from '@/models/doklad'
import { addDays } from 'date-fns'
import { useRouter } from 'vue2-helpers/vue-router'
import { ODataService } from '@/services/odata.service'
import http from '@/helpers/http'
import { useSnackbar } from '@/core/composable'
import { defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
    name: 'SPracovnikDoklad',
    props: {
        readonly: {
            type: Boolean,
        },
    },
    emits: ['close'],
    setup() {
        const router = useRouter()

        const snackbar = useSnackbar()

        const odata = new ODataService()

        const uploadingFiles = ref(false)
        const saving = ref(false)
        const deleting = ref(false)
        const modal = ref(false)
        const changed = ref(false)
        const dokladId = ref('')
        const doklads = ref<Doklad[]>([])
        const soubors = ref<{ soubor: Soubor }[]>([])
        const souborsForUpload = ref<Blob[]>([])
        const item = ref<DokladInput>({} as DokladInput)
        const form = ref({} as VForm)
        const rules = {
            cisloRequired: [(v: string) => !!v || 'Číslo musí být vyplněno'],
            nazevRequired: [(v: string) => !!v || 'Typ dokladu musí být vyplněn'],
            dKonecPlatnostRequired: [(v: string) => !!v || 'Datum expirace musí být vyplněn'],
        }

        const dokladHeaders = [
            { text: 'Číslo', value: 'cislo', sortable: false, divider: true },
            {
                text: 'Typ dokladu',
                value: 'nazev',
                sortable: false,
                divider: true,
            },
            { text: 'Datum expirace', value: 'dKonecPlatnost', sortable: false, width: 130 },
        ] as DataTableHeader[]

        const souborHeaders = [
            { text: 'Název', value: 'soubor.nazev', sortable: false, divider: true },
            { text: 'Přidáno', value: 'dVytvoreno', sortable: false, width: 150 },
        ] as DataTableHeader[]

        const fetchData = () => {
            odata
                .getList<Doklad>('doklad', {
                    select: ['id', 'nazev', 'cislo', 'dKonecPlatnost', 'pracovnikId', 'dVytvoreno', 'dUpraveno'],
                    orderBy: ['dKonecPlatnost desc'] as any,
                    filter: { pracovnikId: router.currentRoute.params.pracovnikId },
                    expand: {
                        vytvorilUzivatel: { select: ['celeJmeno'] },
                        upravilUzivatel: { select: ['celeJmeno'] },
                        dokladSoubors: {
                            expand: { soubor: { select: ['id', 'nazev', 'dVytvoreno'] } },
                            orderBy: ['dVytvoreno desc'] as any,
                        },
                    },
                })
                .then((a) => {
                    doklads.value = a.data.value
                })
        }

        const getColor = (date: Date) => {
            if (new Date(date) <= addDays(new Date(), 5)) {
                return 'red'
            }

            if (new Date(date) <= addDays(new Date(), 10)) {
                return 'orange'
            }

            return ''
        }

        const addDoklad = () => {
            dokladId.value = ''
            item.value = { pracovnikId: router.currentRoute.params.pracovnikId, soubors: [] } as any
            soubors.value = []

            form.value.resetValidation()

            nextTick(() => {
                changed.value = false
                modal.value = true
            })
        }

        const editDoklad = (i: Doklad) => {
            item.value = { ...(i as unknown as DokladInput), soubors: [] }
            soubors.value = [...i.dokladSoubors]
            dokladId.value = i.id

            form.value.resetValidation()

            nextTick(() => {
                changed.value = false
                modal.value = true
            })
        }

        const uploadSoubors = () => {
            var formData = new FormData()
            souborsForUpload.value.forEach((s) => {
                formData.append('files', s)
            })

            if (souborsForUpload.value.length) {
                uploadingFiles.value = true
                http.post('/api/file/upload', formData)
                    .then((a) => {
                        soubors.value.push(
                            ...(a.data as unknown as Soubor[]).map((m) => {
                                return { soubor: m }
                            })
                        )
                        item.value.soubors.push(...(a.data as unknown as Soubor[]).map((m) => m.id))

                        souborsForUpload.value = []
                    })
                    .finally(() => (uploadingFiles.value = false))
            }
        }

        const close = () => {
            if (changed.value && !confirm('Opravdu chcete zavřít doklad bez uložení dat?')) {
                return
            }

            souborsForUpload.value = []
            modal.value = false
        }

        const create = () => {
            return odata.post<Doklad>('doklad', item.value)
        }

        const update = () => {
            return odata.put<Doklad>('doklad', dokladId.value, item.value)
        }

        const save = () => {
            if (!form.value.validate()) {
                return
            }

            saving.value = true

            const promise = dokladId.value ? update() : create()
            promise
                .then(() => {
                    snackbar.success('Doklad byl úspěšně založen')

                    fetchData()
                    changed.value = false

                    nextTick(() => {
                        close()
                    })
                })
                .finally(() => (saving.value = false))
        }

        const remove = () => {
            if (!confirm('Opravdu chcete odebrat doklad?')) {
                return
            }

            deleting.value = true
            odata
                .remove('doklad', dokladId.value)
                .then(() => {
                    snackbar.info('Doklad byl úspěšně odebrán')

                    fetchData()
                    changed.value = false

                    nextTick(() => {
                        close()
                    })
                })
                .finally(() => (deleting.value = false))
        }

        const download = (item: { soubor: Soubor }) => {
            http.get<any>('/api/file/download/' + item.soubor.id, { responseType: 'blob' })
                .then((response) => {
                    const blob = new Blob([response.data])
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = decodeURIComponent(response.request.getResponseHeader('content-disposition').split("filename*=UTF-8''")[1].split(';')[0])
                    link.click()
                    URL.revokeObjectURL(link.href)
                })
                .catch(console.error)
        }

        watch(
            () => router.currentRoute.params.pracovnikId,
            (newValue) => {
                if (newValue) {
                    fetchData()
                }
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        return {
            doklads,
            item,
            soubors,
            souborsForUpload,
            uploadingFiles,
            form,
            changed,
            modal,
            saving,
            deleting,
            dokladHeaders,
            souborHeaders,
            rules,
            dokladId,

            getColor,
            addDoklad,
            editDoklad,
            uploadSoubors,
            close,
            save,
            remove,
            download,
        }
    },
})
