
import { ODataResult } from '@/models/odata.result'
import { Pozice } from '@/models/pozice'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import { debounce } from 'lodash'
import { createQuery } from '@/helpers/create-query'
import { ODataService } from '@/services/odata.service'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'
import { computed, defineComponent, reactive, ref } from 'vue'
import { generateExcel } from '@/helpers/excel/data-table'

export default defineComponent({
    name: 'PoziceListPage',
    setup() {
        const odata = new ODataService()
        const router = useRouter()
        const data = ref<ODataResult<Pozice>>({ value: [], '@odata.count': 0 })
        const dataLoading = ref(false)
        const exportLoading = ref(false)
        const store = useStore()
        const filter = reactive({
            sazba: '',
            nazev: '',
        })
        const options = ref<DataTableOptions>({ page: 1, itemsPerPage: 20, sortBy: ['nazev'], sortDesc: [false], multiSort: true })
        const headers = [
            { text: 'Název', value: 'nazev', divider: true },
            { text: 'Sazba', value: 'sazba', divider: true, formatNumber: '0.00' },
        ] as DataTableHeader[]

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const fetchData = () => {
            dataLoading.value = true

            var query = createQuery(options.value)

            const qFilter = []
            if (filter['nazev']) {
                qFilter.push({ nazev: { contains: filter.nazev } })
            }
            if (filter['sazba'] && !isNaN(+filter.sazba)) {
                qFilter.push({ sazba: { ge: +filter.sazba } })
            }

            odata
                .getList<Pozice>('pozice', { ...query, select: ['id', 'nazev', 'sazba'], filter: qFilter })
                .then((a) => (data.value = a.data))
                .finally(() => (dataLoading.value = false))
        }
        const debounceFetchData = debounce(() => {
            fetchData()
        }, 500)

        const open = (e: Pozice) => {
            router.push({ name: 'pozice-detail.page', params: { poziceId: e.id } })
        }

        const exportData = () => {
            exportLoading.value = true

            var query = createQuery(options.value)

            const qFilter = []
            if (filter['nazev']) {
                qFilter.push({ nazev: { contains: filter.nazev } })
            }
            if (filter['sazba'] && !isNaN(+filter.sazba)) {
                qFilter.push({ sazba: { ge: +filter.sazba } })
            }

            odata
                .getList<Pozice>('pozice', { ...query, top: undefined, skip: undefined, filter: qFilter })
                .then(({ data }) => generateExcel('Pozice', headers, data.value, { extreLength: 3 }))
                .finally(() => (exportLoading.value = false))
        }

        return {
            data,
            dataLoading,
            filter,
            options,
            headers,
            isReadOnly,

            exportLoading,
            exportData,

            debounceFetchData,
            fetchData,
            open,
        }
    },
    watch: {
        '$vuetify.breakpoint.smAndUp': {
            immediate: true,
            handler(newValue) {
                this.options.page = 1
                this.options.itemsPerPage = newValue ? 20 : 10

                this.filter.nazev = ''
                this.filter.sazba = ''
                this.fetchData()
            },
        },
    },
})
