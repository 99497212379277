
import { useIsFinance, useSnackbar } from '@/core/composable'
import { formatDate } from '@/helpers/date-format'
import { Finance } from '@/models/finance'
import { Nastaveni } from '@/models/nastaveni'
import { Pozice } from '@/models/pozice'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { ODataService } from '@/services/odata.service'
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'

import FinanceDialog from './finance-dialog.vue'

export default defineComponent({
    name: 'SPracovnikFinance',
    components: {
        FinanceDialog,
    },
    props: {
        poziceId: {
            type: String,
        },
        fSkupinaId: {
            type: String,
        },
        strediskoId: {
            type: String,
        },
        isHpp: {
            type: Boolean,
        },
        readonly: {
            type: Boolean,
        },
        sazbaOdmenaHod: {
            type: Number,
        },
        odmenaPrac: {
            type: Number,
        },
    },
    setup(props) {
        const odata = new ODataService()
        const snackbar = useSnackbar()
        const { isFinance } = useIsFinance()

        const item = ref<Finance>({} as Finance)

        const router = useRouter()

        const finances = ref<Finance[]>([])

        const modal = ref(false)

        const menuDatum = ref(false)

        const isUzamceno = ref(false)
        const isReadOnly = computed(() => props.readonly || isUzamceno.value)

        const year = ref(new Date().getFullYear())
        const years = ref(Array.from({ length: new Date().getFullYear() - 2022 + 2 }, (v, k) => k + 2022))

        const financeHeaders = computed(() => {
            const result = [
                { text: 'Měsíc', value: 'datum', sortable: false, divider: true, width: 90 },
                { text: 'Počet hodin', value: 'odpracovanoHod', sortable: false, divider: true, width: 50, align: 'end' },
            ] as DataTableHeader[]

            if (isFinance) {
                result.push(
                    {
                        text: 'Výplata (Kč)',
                        value: 'celkemVyplata',
                        sortable: false,
                        divider: true,
                        align: 'end',
                        width: 80,
                    },
                    { text: 'Marže agentura (Kč)', value: 'celkemAgentura', sortable: false, align: 'end', width: 100 }
                )
            } else {
                result.push({
                    text: 'Odměna (kč)',
                    value: 'sazbaOdmenaHod',
                    sortable: false,
                    align: 'end',
                })
            }

            return result
        })

        const fetchData = () => {
            odata
                .getList<Finance>('finance', {
                    select: [
                        'id',
                        'datum',
                        'isHpp',
                        'sazbaOdmenaHod',
                        'odpracovanoHod',
                        'odpracovanoHppHod',
                        'srazka',
                        'pokuta',
                        'odmenaMes',
                        'odmenaPrac',
                        ...((isFinance ? ['sazbaHod', 'sazbaAgenturaHod', 'celkemVyplata', 'celkemAgentura'] : []) as any),
                    ],
                    expand: {
                        odmenas: { select: ['id', 'nazev', 'castka'] },
                        srazkas: { select: ['id', 'nazev', 'castka'] },
                        pokutas: { select: ['id', 'nazev', 'castka'] },
                    },
                    orderBy: 'datum desc',
                    filter: { pracovnikId: { eq: router.currentRoute.params.pracovnikId }, 'year(datum)': { eq: year.value } },
                })
                .then((a) => {
                    finances.value = a.data.value
                })
        }

        const yearChange = () => {
            fetchData()
        }

        const addFinance = async () => {
            isUzamceno.value = false

            if (!props.poziceId) {
                snackbar.warn('Není vyplněná pozice')
                return
            }

            if (!props.fSkupinaId) {
                snackbar.warn('Fakturační skupina musí být vyplněna')
                return
            }

            if (!props.strediskoId) {
                snackbar.warn('Středisko musí být vyplněno')
                return
            }

            let pozice: Pozice | null = null
            let marzeAgentura: string | null = null
            if (isFinance) {
                pozice = (await odata.getById<Pozice>('pozice', props.poziceId)).data
                marzeAgentura = (await odata.getList<Nastaveni>('nastaveni', { filter: { klic: { eq: 'AGENTURA_MARZE' } } })).data.value[0].hodnota
            }

            item.value = {
                isHpp: props.isHpp,
                pracovnikId: router.currentRoute.params.pracovnikId,
                sazbaHod: pozice?.sazba,
                poziceId: props.poziceId,
                strediskoId: props.strediskoId,
                fakturacniSkupinaId: props.fSkupinaId,
                sazbaAgenturaHod: marzeAgentura,
                sazbaOdmenaHod: props.sazbaOdmenaHod ?? 0,
                srazka: 0,
                pokuta: 0,
                odmenaMes: 0,
                odmenaPrac: props.odmenaPrac ?? 0,
            } as any

            nextTick(() => {
                modal.value = true
            })
        }

        const detailFinance = async (f: Finance) => {
            item.value = { ...f, datum: formatDate(f.datum, 'yyyy-MM') }

            const rok = +item.value.datum!.toString().split('-')[0]
            const mesic = +item.value.datum!.toString().split('-')[1]

            isUzamceno.value =
                (
                    await odata.getList('uzamceno', {
                        filter: {
                            rok: { eq: rok },
                            mesic: { eq: mesic },
                        },
                        count: true,
                    })
                ).data['@odata.count'] > 0 ||
                rok < 2022 ||
                (rok === 2022 && mesic < 9)

            nextTick(() => {
                modal.value = true
            })
        }

        watch(
            () => router.currentRoute.params.pracovnikId,
            (newValue) => {
                if (newValue) {
                    fetchData()
                }
            },
            { immediate: true }
        )

        const onClose = () => {
            fetchData()

            modal.value = false
        }

        return {
            isReadOnly,
            financeHeaders,

            finances,
            item,

            modal,

            year,
            years,
            yearChange,

            menuDatum,

            onClose,

            addFinance,
            detailFinance,
        }
    },
})
