
import { formatDate } from '@/helpers/date-format'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SDatePicker',
    props: {
        value: {
            type: [Date, String],
        },
        label: {
            type: String,
        },
        readonly: {
            type: Boolean,
        },
        dense: {
            type: Boolean,
            default: true,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        required: Boolean,
        rules: {
            default: () => [],
        },
    },
    emits: ['input', 'change'],
    setup(props, { emit }) {
        const modal = ref(false)
        const isReadOnly = computed(() => props.readonly as boolean)
        const ruleset = computed(() => {
            const result: any[] = [...(props.rules as any[])]
            if (props.required) result.push((v: any) => !!v || props.label + ' musí být vyplněn')
            return result
        })
        const computerFormat = computed(() => (props.value ? formatDate(props.value as string, 'yyyy-MM-dd') : null))
        const humanFormat = computed(() => (props.value ? formatDate(props.value as string) : null))

        const onClearClicked = () => {
            if (!isReadOnly.value) {
                emit('input', null)
                emit('change', null)
            }
        }

        const onInputChange = (e: any) => {
            emit('input', e ? new Date(e) : null)
            emit('change', e ? new Date(e) : null)

            modal.value = false
        }

        return {
            modal,
            isReadOnly,
            ruleset,
            computerFormat,
            humanFormat,

            onClearClicked,
            onInputChange,
        }
    },
})
