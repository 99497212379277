
import { useIsAdmin } from '@/core/composable'
import { createQuery } from '@/helpers/create-query'
import { generateExcel } from '@/helpers/excel/data-table'
import { unaccent } from '@/helpers/string-format'
import { ODataResult } from '@/models/odata.result'
import { Pozice } from '@/models/pozice'
import { Pracovnik } from '@/models/pracovnik'
import { Slovnik } from '@/models/slovnik'
import { Stredisko } from '@/models/stredisko'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import odata from '@/services/odata.service'
import { debounce } from 'lodash'
import { Expand } from 'odata-query'
import { computed, defineComponent, onBeforeMount, onMounted, ref } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'PracovnikListPage',
    setup() {
        useStore().commit('SET_BREADCRUMPS', [
            { nazev: 'Nástěnka', page: { name: 'nastenka.page' } },
            { nazev: 'Pracovníci', page: { name: 'pracovnik-list.page' } },
        ])

        const pracovnik_filter = sessionStorage.getItem('pracovnik_filter')
        const pracovnik_options = sessionStorage.getItem('pracovnik_options')

        const { isAdmin } = useIsAdmin()
        const router = useRouter()
        const store = useStore()

        const dataLoading = ref(false)
        const exportLoading = ref(false)

        const filter = ref({ strediskos: [], pozices: [], odebranoByznys: false, priznaks: [], isHpp: null } as any)

        const strediskos = ref<Stredisko[]>([])
        const pozices = ref<Pozice[]>([])
        const slovniks = ref<Slovnik[]>([])

        const data = ref<ODataResult<Pracovnik>>({ value: [], '@odata.count': 0 })

        const filterHpp = ref([
            { text: 'Vše', value: null },
            { text: 'Ano', value: true },
            { text: 'Ne', value: false },
        ])
        let options = ref<DataTableOptions>({
            page: 1,
            itemsPerPage: 20,
            multiSort: true,
            sortBy: ['prijmeni', 'jmeno'],
            sortDesc: [false, false],
        })

        const tmpHeaders = [
            { text: 'Doklad (pas)', value: 'cisloDoklad', divider: true },
            { text: 'Byznys č.', value: 'cisloByznys', divider: true },
            { text: 'Příjmení', value: 'prijmeni', divider: true },
            { text: 'Jméno', value: 'jmeno', divider: true },
            { text: 'Nástup', value: 'dNastup', divider: true, date: true },
            { text: 'Výstup', value: 'dVystup', divider: true, date: true },
            { text: 'Přestup', value: 'dPrestup', divider: true, date: true },
            { text: 'Středisko', value: 'stredisko.celyNazev', divider: true },
            { text: 'Pozice', value: 'pozice.nazev', divider: true },
            { text: 'Ukončení víza', value: 'dVizoKonec', divider: true, date: true },
        ] as DataTableHeader[]

        const headers = computed(() => {
            const result = [...tmpHeaders]

            if (isAdmin) {
                result.push({
                    text: 'Příznak',
                    value: 'priznaks',
                    divider: true,
                    data: (row: Pracovnik) => row.priznaks?.map((m) => m.slovnik.zkratka).join(', '),
                })
            }

            result.push({ text: filter.value.odebranoByznys ? 'HPP' : 'Částečné HPP', value: 'isHpp', divider: true, bool: true })
            result.push({ text: 'Aktivní EvPraDo', value: 'odebranoByznys', negation: true })

            return result
        })

        const paginationLength = computed(() => Math.ceil(data.value['@odata.count'] / options.value.itemsPerPage))

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const getFilter = () => {
            const _filter: any[] = []

            if (filter.value.cisloDoklad) _filter.push({ cisloDoklad: { contains: filter.value.cisloDoklad } })
            if (filter.value.cisloByznys) _filter.push({ cisloByznys: { contains: filter.value.cisloByznys } })
            if (filter.value.jmeno) _filter.push({ normJmeno: { contains: unaccent(filter.value.jmeno) } })
            if (filter.value.prijmeni) _filter.push({ normPrijmeni: { contains: unaccent(filter.value.prijmeni) } })
            if (filter.value.dNastup) _filter.push({ dNastup: { eq: filter.value.dNastup } })
            if (filter.value.dVystup) _filter.push({ dVystup: { eq: filter.value.dVystup } })
            if (filter.value.dPrestup) _filter.push({ dPrestup: { eq: filter.value.dPrestup } })
            if (filter.value.strediskos && filter.value.strediskos.length) _filter.push({ strediskoId: { in: filter.value.strediskos } })
            if (filter.value.strediskoId) _filter.push({ strediskoId: { eq: filter.value.strediskoId } })
            if (filter.value.dVizoKonec) _filter.push({ dVizoKonec: { eq: filter.value.dVizoKonec } })
            if (filter.value.priznaks && filter.value.priznaks.length) _filter.push({ priznaks: { any: { slovnikId: { in: filter.value.priznaks } } } })
            if (filter.value.pozices && filter.value.pozices.length) _filter.push({ poziceId: { in: filter.value.pozices } })

            _filter.push({ odebranoByznys: { eq: filter.value.odebranoByznys } })

            if (filter.value.isHpp !== null) _filter.push({ isHpp: { eq: filter.value.isHpp } })

            return _filter
        }

        const fetchData = () => {
            const query = createQuery(options.value)

            dataLoading.value = true

            const expand: Expand<Pracovnik> = {
                stredisko: { select: ['id', 'celyNazev'] },
                pozice: { select: ['id', 'nazev'] },
            }
            if (isAdmin) {
                expand.priznaks = { expand: { slovnik: { select: ['zkratka'] } } }
            }

            sessionStorage.setItem('pracovnik_filter', JSON.stringify(filter.value))
            sessionStorage.setItem('pracovnik_options', JSON.stringify(options.value))

            odata
                .getList<Pracovnik>('pracovnik', {
                    ...query,
                    filter: getFilter(),
                    expand,
                    select: [
                        'id',
                        'celeJmeno',
                        'prijmeni',
                        'jmeno',
                        'cisloByznys',
                        'cisloDoklad',
                        'dNastup',
                        'dVystup',
                        'dPrestup',
                        'dVizoKonec',
                        'odebranoByznys',
                        'isHpp',
                    ],
                })
                .then((a) => (data.value = a.data))
                .finally(() => (dataLoading.value = false))
        }
        const debounceFetchData = debounce(fetchData, 400)

        const showHistory = () => {
            filter.value.odebranoByznys = false

            fetchData()
        }

        const open = (item: Pracovnik) => {
            router.push({ name: 'pracovnik-detail.page', params: { pracovnikId: item.id } })
        }

        const exportExcel = () => {
            exportLoading.value = true

            const expand: Expand<Pracovnik> = {
                stredisko: { select: ['id', 'celyNazev'] },
                pozice: { select: ['id', 'nazev'] },
            }
            if (isAdmin) {
                expand.priznaks = { expand: { slovnik: { select: ['zkratka'] } } }
            }

            const tmpQuery = createQuery(options.value)

            odata
                .getList<Pracovnik>('pracovnik', {
                    filter: getFilter(),
                    expand,
                    orderBy: tmpQuery.orderBy,
                    select: [
                        'id',
                        'prijmeni',
                        'jmeno',
                        'cisloByznys',
                        'cisloDoklad',
                        'dNastup',
                        'dVystup',
                        'dPrestup',
                        'dVizoKonec',
                        'odebranoByznys',
                        'isHpp',
                    ],
                })
                .then((a) => {
                    generateExcel('Pracovnici', headers.value, a.data.value)
                })
                .finally(() => (exportLoading.value = false))
        }

        onMounted(() => {
            odata.getList<Stredisko>('stredisko', { orderBy: ['cislo'] }).then((a) => (strediskos.value = a.data.value))
            odata.getList<Pozice>('pozice', { orderBy: 'nazev' }).then((a) => (pozices.value = a.data.value))
            if (isAdmin) {
                odata.getList<Slovnik>('slovnik', { orderBy: ['zkratka'] }).then((a) => (slovniks.value = a.data.value))
            }
        })

        onBeforeMount(() => {
            if (pracovnik_filter) {
                try {
                    filter.value = JSON.parse(pracovnik_filter)
                } catch {
                    sessionStorage.removeItem('pracovnik_filter')
                }
            }

            if (pracovnik_options) {
                try {
                    options.value = JSON.parse(pracovnik_options)
                } catch {
                    sessionStorage.removeItem('pracovnik_options')
                }
            }
        })

        return {
            data,
            headers,
            options,
            filter,
            paginationLength,
            exportLoading,
            strediskos,
            pozices,
            slovniks,
            dataLoading,
            isReadOnly,
            filterHpp,

            showHistory,
            fetchData,
            debounceFetchData,
            open,
            exportExcel,
        }
    },
    watch: {
        '$vuetify.breakpoint.xs': {
            immediate: true,
            handler(newValue) {
                this.options.itemsPerPage = newValue ? 10 : 20

                this.filter.strediskos = []
                this.filter.odebranoByznys = false

                this.$nextTick(() => {
                    this.fetchData()
                })
            },
        },
    },
})
