
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        mini: { type: Boolean, default: false },
    },
    emits: ['miniVariant'],
    setup(_, { emit }) {
        return {
            emit,
        }
    },
})
