import router from '@/router'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { LOGIN_PAGE } from './../modules/auth/auth.routes'

const http = axios.create({})

http.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    (error: AxiosError) => {
        if (error.response?.status == 401) {
            router.push({ name: LOGIN_PAGE, query: { redirectTo: router.currentRoute.path } })
        }

        if (error.response?.status == 403) {
            router.push('/')
        }

        return Promise.reject(error)
    }
)

export default http
