import { round } from '@/helpers/round'
import { FakturacniSkupina } from './fakturacni-skupina'
import { PenezniPolozka } from './penezni-polozka'
import { Pozice } from './pozice'
import { Pracovnik } from './pracovnik'
import { Stredisko } from './stredisko'

export function odpracovanoHodTmp(odpracovanoHod: number, srazka: number, pokuta: number, sazbaHod: number) {
    if (!srazka && !pokuta) return odpracovanoHod
    if (!sazbaHod) return odpracovanoHod

    return odpracovanoHod - round((srazka + pokuta) / sazbaHod, 1)
}

export interface Finance {
    id?: string

    pracovnikId: string
    poziceId: string
    strediskoId: string
    fakturacniSkupinaId: string

    isHpp: boolean

    pracovnik: Pracovnik
    pozice: Pozice
    stredisko: Stredisko
    fakturacniSkupina: FakturacniSkupina

    datum: Date | string | null

    srazka: number
    pokuta: number

    sazbaAgenturaHod: number
    sazbaHod: number
    sazbaOdmenaHod: number
    odpracovanoHppHod: number
    odpracovanoHod: number
    odmenaMes: number
    odmenaPrac: number

    odpracovanoHodPoSrazce: number

    celkemZaklad?: number
    celkemOdmena?: number
    celkemVyplata?: number
    celkemFaktura?: number
    celkemAgentura?: number

    odmenas: PenezniPolozka[]
    srazkas: PenezniPolozka[]
    pokutas: PenezniPolozka[]
}
