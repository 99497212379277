import ZmenaHeslaPage from '@/modules/auth/pages/heslo.page.vue'
import LoginPage from '@/modules/auth/pages/login.page.vue'
import { RouteConfig } from 'vue-router'

export const LOGIN_PAGE = 'login.page'

export const AUTH_ROUTES: Array<RouteConfig> = [
    {
        path: '',
        name: LOGIN_PAGE,
        component: LoginPage,
    },
    {
        path: 'zmena-hesla',
        name: 'zmena-hesla.page',
        component: ZmenaHeslaPage,
    },
    {
        path: '*',
        redirect: { name: LOGIN_PAGE },
    },
]
