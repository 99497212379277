import { Role } from '@/models/role'
import { useStore } from 'vue2-helpers/vuex'

export function useIsAdmin() {
    const store = useStore()
    const isAdmin = !!store.getters.uzivatel?.uzivatelRoles?.map((m: any) => m.role)?.find((f: Role) => f.name === 'ADMIN')

    return {
        isAdmin,
    }
}
