
import { Pracovnik } from '@/models/pracovnik'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { VForm } from '@/models/vuetify/vform'
import { unaccent } from '@/helpers/string-format'
import { ODataService } from '@/services/odata.service'
import { useSnackbar } from '@/core/composable'
import { defineComponent, nextTick, ref, watch } from 'vue'

export default defineComponent({
    name: 'SCisloByznysNastenka',
    props: {
        pracovnik: {
            type: Object as () => Pracovnik,
        },
    },
    emits: ['close'],
    setup(props, { emit }) {
        const snackbar = useSnackbar()
        const odata = new ODataService()
        const formCisloByznys = ref({} as VForm)
        const saving = ref(false)
        const cisloByznys = ref('')
        const pracovnik = ref<Pracovnik | undefined>()
        const headers = [
            { text: 'Číslo Byznys', value: 'cisloByznys', divider: true },
            { text: 'Jméno', value: 'celeJmeno', divider: true },
            { text: 'Datum narození', value: 'dNarozeni', divider: true },
            { text: 'Středisko', value: 'stredisko.nazev', divider: true },
            { text: 'Nástup', value: 'dNastup', divider: true },
            { text: 'Výstup', value: 'dVystup' },
        ] as DataTableHeader[]

        const data = ref<Pracovnik[]>([])
        const rules = {
            cisloByznysRequired: [(v: string) => !!v || 'Číslo Byznys musí být vyplněno'],
        }

        const fetchData = () => {
            const filter = []
            filter.push({ id: { ne: pracovnik.value?.id } })
            filter.push({ normJmeno: { contains: unaccent(pracovnik.value?.jmeno || '') } })
            filter.push({ normPrijmeni: { contains: unaccent(pracovnik.value?.prijmeni || '') } })
            if (pracovnik.value?.dNarozeni) filter.push({ dNarozeni: { eq: new Date(pracovnik.value.dNarozeni.toString()) } })

            odata
                .getList<Pracovnik>('pracovnik', { expand: { stredisko: { select: ['id', 'nazev'] } }, filter, orderBy: ['celeJmeno'] })
                .then((a) => (data.value = a.data.value))
        }

        const close = () => {
            formCisloByznys.value.resetValidation()
            emit('close')
        }

        const save = () => {
            if (formCisloByznys.value.validate()) {
                console.log(pracovnik)
                saving.value = true
                odata
                    .patch('pracovnik', pracovnik.value?.id || '', { cisloByznys: cisloByznys.value })
                    .then(() => {
                        snackbar.success('Číslo Byznys bylo přiřazeno')

                        emit('close')
                    })
                    .finally(() => (saving.value = false))

                formCisloByznys.value.resetValidation()
            }
        }

        watch(
            () => props.pracovnik,
            (newValue) => {
                cisloByznys.value = ''
                pracovnik.value = newValue

                nextTick(() => {
                    fetchData()
                })
            },
            { immediate: true }
        )

        return {
            data,
            formCisloByznys,
            saving,
            cisloByznys,
            headers,
            rules,

            close,
            save,
        }
    },
})
