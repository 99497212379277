
import { useRouter } from 'vue2-helpers/vue-router'
import { debounce } from 'lodash'

import { createQuery } from '@/helpers/create-query'
import { ODataService } from '@/services/odata.service'

import { ODataResult } from '@/models/odata.result'
import { Slovnik } from '@/models/slovnik'
import { DataTableHeader } from '@/models/vuetify/datatable/datatable.header'
import { DataTableOptions } from '@/models/vuetify/datatable/datatable.options'
import { useStore } from 'vue2-helpers/vuex'
import { computed, defineComponent, reactive, ref } from 'vue'

export default defineComponent({
    name: 'SlovnikListPage',
    setup() {
        const router = useRouter()
        const odata = new ODataService()
        const store = useStore()

        const dataLoading = ref(false)
        const data = ref<ODataResult<Slovnik>>({ value: [], '@odata.count': 0 } as ODataResult<Slovnik>)
        const filter = reactive({
            zkratka: '',
            nazev: '',
        })
        const options = ref<DataTableOptions>({
            page: 1,
            itemsPerPage: 20,
            sortBy: ['zkratka'],
            sortDesc: [false],
            multiSort: true,
        } as DataTableOptions)
        const headers = [
            { text: 'Zkratka', value: 'zkratka', divider: true },
            { text: 'Název', value: 'nazev' },
        ] as DataTableHeader[]

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const fetchData = () => {
            dataLoading.value = true

            const query = createQuery(options.value)

            const qFilter = []
            if (filter.zkratka) {
                qFilter.push({ zkratka: { contains: filter.zkratka } })
            }
            if (filter.nazev) {
                qFilter.push({ nazev: { contains: filter.nazev } })
            }

            odata
                .getList<Slovnik>('slovnik', { ...query, select: ['id', 'zkratka', 'nazev'], filter: qFilter })
                .then((a) => (data.value = a.data))
                .finally(() => (dataLoading.value = false))
        }
        const debounceFetchData = debounce(fetchData, 500)
        const open = (item: Slovnik) => {
            router.push({ name: 'slovnik-detail.page', params: { slovnikId: item.id } })
        }

        return {
            dataLoading,
            data,
            filter,
            options,
            headers,
            isReadOnly,

            open,
            fetchData,
            debounceFetchData,
        }
    },
    watch: {
        '$vuetify.breakpoint.smAndUp': {
            handler(newValue) {
                this.options.page = 1
                this.options.itemsPerPage = newValue ? 20 : 10

                this.filter.zkratka = ''
                this.filter.nazev = ''

                this.fetchData()
            },
        },
    },
})
