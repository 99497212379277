import { ref } from 'vue'
import { NavigationGuardNext, Route } from 'vue-router'

import { onBeforeRouteLeave } from 'vue2-helpers/vue-router'

export function useCheckChanges() {
    const changed = ref(false)

    onBeforeRouteLeave((_to: Route, _from: Route, next: NavigationGuardNext) => {
        if (changed.value && !confirm('Opravdu chcete odejít bez uložení změn?')) {
            next(false)
        } else {
            changed.value = false
            next()
        }
    })

    return {
        changed,
    }
}
