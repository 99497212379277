
import { useStore } from 'vue2-helpers/vuex'

import http from '@/helpers/http'
import { Uzivatel } from '@/models/uzivatel'
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'SAppBar',
    setup() {
        const uzivatel = computed(() => useStore().getters.uzivatel as Uzivatel)
        const logout = () => {
            http.get('/api/auth/logout').finally(() => location.reload())
        }

        return {
            uzivatel,

            logout,
        }
    },
})
