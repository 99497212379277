import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { APP_ROUTES } from './../modules/app/app.routes'

import Layout from '@/core/layout/app-layout.vue'

import AppView from '@/modules/app/app-view.vue'

import { ADMIN_ROUTES } from '@/modules/admin/admin.routes'
import { AUTH_ROUTES } from '@/modules/auth/auth.routes'
import { createRouter } from 'vue2-helpers/vue-router'
import { isAdmin } from './guards/isAdmin'
import { isFinance } from './guards/isFinance'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: 'admin',
                component: () => import('../modules/admin/admin-view.vue'),
                children: ADMIN_ROUTES,
                meta: {
                    isAdmin: true,
                },
            },
            {
                path: 'app',
                component: AppView,
                children: APP_ROUTES,
            },
            {
                path: '*',
                redirect: { name: 'nastenka.page' },
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('../modules/auth/auth-view.vue'),
        children: AUTH_ROUTES,
    },
    {
        path: '*',
        redirect: { name: 'nastenka.page' },
    },
]

const router = createRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    if (to.matched.some((s) => s.meta.isAdmin)) {
        isAdmin(to, from, next)
    }

    if (to.matched.some((s) => s.meta.isFinance)) {
        isFinance(to, from, next)
    }

    next()
})

export default router
