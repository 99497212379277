
import { AxiosError } from 'axios'

import { VForm } from '@/models/vuetify/vform'
import http from '@/helpers/http'
import { useRouter } from 'vue2-helpers/vue-router'
import { useSnackbar } from '@/core/composable'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SZmenaHesla',
    setup() {
        const router = useRouter()
        const snackbar = useSnackbar()

        const form = ref({} as VForm)

        const isLoading = ref(false)
        const errorMessage = ref('')

        const soucasneHeslo = ref('')
        const noveHeslo = ref('')
        const noveHesloKontrola = ref('')

        const rules = {
            soucasneHesloRequired: [(value: string) => !!value || 'Heslo musí být vyplněno'],
            noveHesloRequired: [(value: string) => !!value || 'Nové heslo musí být vyplněno'],
            noveHesloKontrola: [(value: string) => value == noveHeslo.value || 'Hesla se musí shodovat'],
        }

        const save = () => {
            if (!form.value.validate()) return

            isLoading.value = true

            http.post('/api/auth/zmenaHesla', { stareHeslo: soucasneHeslo.value, noveHeslo: noveHeslo.value })
                .then(() => {
                    snackbar.success('Heslo bylo změněno')
                    router.push({ name: 'nastenka.page' })
                })
                .catch((response: AxiosError) => {
                    if (response.response?.status == 400) {
                        errorMessage.value = 'Špatné současné heslo'
                    } else {
                        errorMessage.value = 'Problém s připojením k serveru'
                    }
                })
                .finally(() => {
                    isLoading.value = false
                })
        }

        return {
            form,
            isLoading,
            errorMessage,
            soucasneHeslo,
            noveHeslo,
            noveHesloKontrola,
            rules,

            save,
        }
    },
})
