
import { useCheckChanges, useSnackbar } from '@/core/composable'
import { Stredisko } from '@/models/stredisko'
import { VForm } from '@/models/vuetify/vform'
import { ODataService } from '@/services/odata.service'
import { computed, defineComponent, ref, watch } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { useStore } from 'vue2-helpers/vuex'

export default defineComponent({
    name: 'StrediskoDetailPage',
    props: {
        strediskoId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const odata = new ODataService()
        const snackbar = useSnackbar()
        const store = useStore()
        const router = useRouter()

        const { strediskoId } = props
        const item = ref<Stredisko>({} as Stredisko)
        const form = ref({} as VForm)
        const saving = ref(false)
        const { changed } = useCheckChanges()
        const rules = {
            cisloRules: [(v: string) => !!v || 'Číslo musí být vyplněno'],
            nazevRules: [(v: string) => !!v || 'Název musí být vyplněn'],
        }

        const isReadOnly = computed(() => store.getters.isReadOnly)

        const fetchItem = () => {
            if (strediskoId) {
                odata
                    .getById<Stredisko>('stredisko', strediskoId, {
                        expand: { upravilUzivatel: { select: ['celeJmeno'] }, vytvorilUzivatel: { select: ['celeJmeno'] } },
                    })
                    .then((i) => (item.value = i.data))
                    .finally(() => (changed.value = false))
            }
        }

        const create = () => {
            return odata.post('stredisko', item.value)
        }

        const update = () => {
            return odata.put('stredisko', strediskoId, item.value)
        }

        const save = () => {
            if (!form.value.validate()) return

            const promise = strediskoId ? update() : create()
            promise
                .then(() => {
                    snackbar.success('Středisko bylo uloženo')
                    changed.value = false
                    router.push({ name: 'stredisko-list.page' })
                })
                .catch(() => {
                    snackbar.error('Středisko se nepodařilo uložit')
                })
                .finally(() => (changed.value = false))
        }

        watch(
            () => strediskoId,
            () => {
                fetchItem()
            },
            { immediate: true }
        )

        watch(
            () => item,
            () => {
                changed.value = true
            },
            { deep: true }
        )

        return {
            form,
            item,
            rules,
            saving,
            changed,
            isReadOnly,

            save,
        }
    },
})
