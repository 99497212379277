
import { formatNumber } from '@/helpers/number-format'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        value: {
            type: [String, Number],
        },
        readonly: Boolean,
        dense: {
            type: Boolean,
            default: true,
        },
        label: String,
        suffix: String,
        prefix: String,
        hint: String,
        clearable: Boolean,
        required: Boolean,
        persistentHint: Boolean,
        digits: Number,
        appendIcon: String,
        prependIcon: String,
        prependInnerIcon: String,
        rules: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const isFocused = ref(false)

        const isReadonly = computed(() => {
            return props.readonly
        })

        const ruleset = computed(() => {
            const result = [...props.rules]
            if (props.required && !isReadonly.value) result.push((v: any) => !!v || props.label + ' musí být vyplněn')
            return result
        })

        const userFormat = computed(() => {
            if (!isFocused.value) {
                return formatNumber(props.value ?? null, props.digits)
            }

            return formatNumber(props.value ?? null)
        })

        const reverseFormatNumber = (val: string, locale: string) => {
            const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
            const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
            let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
            reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
            return Number.isNaN(reversedVal) ? 0 : reversedVal
        }

        const getNumber = (val: any) => {
            if (val != null && val != undefined && val != '') {
                const number = +reverseFormatNumber(val, 'cs-CZ')
                if (!isNaN(number)) {
                    return number
                }
            }
            return null
        }

        const onChangeInput = (val: string) => {
            emit('input', getNumber(val))
        }

        const onBlur = () => (isFocused.value = false)

        const onFocus = (e: Event) => {
            isFocused.value = true
            ;(e.target as HTMLInputElement).select()
        }

        return {
            userFormat,
            isReadonly,
            ruleset,
            onChangeInput,
            onBlur,
            onFocus,
        }
    },
})
