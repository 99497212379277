import { Role } from '@/models/role'
import { useStore } from 'vue2-helpers/vuex'

export function useIsFinance() {
    const store = useStore()
    const isFinance = !!store.getters.uzivatel?.uzivatelRoles?.map((m: any) => m.role)?.find((f: Role) => f.name === 'FINANCE')

    return {
        isFinance,
    }
}
