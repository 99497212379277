import http from '@/helpers/http'

type VyplatniPaskaFilter = {
    rok: number
    mesic: number
    pracovnik?: string
    strediskos?: string[]
    fakturacniSkupinas?: string[]
}

export class ReportService {
    vyplatniPaska(filter: VyplatniPaskaFilter) {
        return http.post('/api/report/vyplatni-paska', filter, { responseType: 'blob' }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `vyplatni-paska-${filter.rok}${filter.mesic}.xlsx`)
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        })
    }
}

export default new ReportService()
