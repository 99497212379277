import { onMounted, onUnmounted, ref } from 'vue'

export function useSize() {
    const width = ref(window.innerWidth)
    const height = ref(window.innerHeight)

    const resizeCallback = () => {
        width.value = window.innerWidth
        height.value = window.innerHeight
    }

    onMounted(() => {
        window.addEventListener('resize', resizeCallback)
    })

    onUnmounted(() => {
        window.removeEventListener('resize', resizeCallback)
    })

    return { width, height }
}
